import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    const activeElem = this.element.querySelector('.active');

    if( activeElem == null ) return;
    
    const collapseTarget = activeElem.closest('.collapse');
    $(collapseTarget).collapse('toggle');
  }
}
<template>
<div class="text-right">
  <button @click.prevent="showDialog">OJ kurzy</button>


    <modal name="example2" :resizable="true" :adaptive="false" :height="500">
      <div class="modbod text-left">

        <h3>Kontrola kurzu v OJ</h3>
        <table class="table table-striped">
          <tr v-for="c in ojc">
            <td>{{ c.productName }}</td>
            <td>{{ c.expireDate }}</td>
            <td>{{ c.isTrial ? "Trial" : '' }}</td>
          </tr>
        </table>
        <div>
          <button class="btn-outline-dark" @click.prevent="runSync"> synchronizaovat do OJ</button>
        </div>


      </div>
    </modal>



</div>
</template>

<script>

import axios from "axios";

export default {
  name: "ojsyncshow",
  props:['student'],

  data(){
    return ({
      ojc:[]
    })
  },

  methods:{

    showDialog(){
      this.$modal.show('example2')

      axios.get(`/students/${this.student.id}/oj-courses`).then(response => {
        this.ojc = response.data.courses
      })

    },

    runSync(){
      axios.get(`/students/${this.student.id}/oj-courses-sync`).then(response => {
         this.showDialog()
      })
    }



  }
}
</script>

<style scoped>
.modbod{
  text-align: left;
  height:450px;
  overflow-y: scroll;
  margin:20px;

}
</style>
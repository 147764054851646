<script setup>


export default {
  name: "FInputText",
  props: ['options', 'item']
}
</script>

<template>
<div class="form-group">
  <label>{{options.label}}  </label>
  <input type="text" class="form-control" v-model="item[options.codename]"/>
</div>
</template>

<style scoped>

</style>
/**
 * Used for any customization for date time pickers in app
 */

$(function () {
  // Using font-awesome 5 icons by default & others defaults

  if ($.fn.datetimepicker.Constructor) {

    $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar-alt',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'far fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'fas fa-times'
      }
    })
  }
  /**
   * Auto close datetime picker on blur
   */
  $(document).on('mouseup touchend', function (e) {
    const container = $('.bootstrap-datetimepicker-widget')
    if (container.length === 0) return
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.parent().datetimepicker('hide')
    }
  })
})

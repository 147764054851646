<template>

  <div>
  <v-select label="name" :filterable="true" :options="products"  v-model="selected_product" placeholder="Add Product" @input="onProductSelect">

    <template #option="option">
      <div class="d-flex">
        <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct">
        <div style="width: 300px">{{ option.name }}</div>
        <div class="ml-5">{{option.price_monthly_czk ? `${option.price_monthly_czk} Kč` : `${option.price_monthly_eur } EUR`}}/m  </div>

      </div>
    </template>
    <template #selected-option="option">
      <div class="selected d-center">
        <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
      </div>
    </template>
  </v-select>

  <input type="hidden" :name="name" :value="selected_product.id" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  props:['name','product'],
  name: "select-product",
  data(){
    return {selected_product:{}, products:[]}
  },
  methods:{
    onProductSelect(){
      if (typeof this.$parent.onProductSelect == 'function')
       this.$parent.onProductSelect(this.selected_product)

      this.$emit('onProductSelect', this.selected_product)

    },
    loadProducts(){

      axios.get('/api/products?group=sub').then( response =>{
        this.products = response.data
      })
    }
  },
  watch:{
    product(){
      if (this.product)
        this.selected_product = this.product
      else
        this.selected_product = {}
    },

    selected_product(){

      this.$emit('onProductSelect', this.selected_product)
    }
  },
  mounted() {
    this.loadProducts()
    this.selected_product = this.product
  }
}
</script>

<style scoped>

</style>
<script>
import AppTable from "../form-generator/AppTable.vue";

export default {
  name: "app-ac-list-log",
  components: {AppTable},
  props: [],

  data() {
    return ({
      fields: [

        {
          "label": "Student",
          "type": "text",
          "codename": "email"
        },

        {
          "label": " ID listu",
          "type": "",
          "codename": "list"
        },

        {
          "label": "Vytvořeno",
          "type": "dateTime",
          display_filter:'dateTime',
          "codename": "created_at"
        }

      ],
      filters:[
        {
          "label": "Vytvorene",
          "type": "date-range",
          "codename": "created_at"
        },{
          type: 'search'
        }
      ]

      ,
      app: {
        data_url: '/utilities/ac-list-log2',
        actions: ""
      }
    })
  },
  methods:{
    logExpander(d){

      for(let i in d){
        let p = JSON.parse(d[i].params)
        d[i].p = p
        d[i]['list'] = p['list']
      }
      return d
    }
  }
}
</script>

<template>
<div>

   <AppTable :app="app" :fields="fields" :datatransformer="logExpander" :default_per="50" :enable_search="true" :filters="filters"></AppTable>
</div>
</template>

<style scoped>

</style>
<script setup>
export default {
  name: "FInputCategory",
  props: ['options', 'item'],
  data(){
      return {
       kkk:213123
      }
  },
  methods:{
    setsel(k){
      for(let i in this.options.items){
        this.options.items[i].selected=false
      }

      k.selected=true
      this.item[this.options.codename] = k.value
      console.log('ch', this.item, k.value)
      this.$emit('change', k.value)
      this.kkk++
    }

  },
  created() {
    for(let i in this.options.items){
      this.options.items[i].selected=false
    }
  }

}
</script>

<template>

    <div class="form-group">
      <label>{{options.label}} </label>


      <span v-for="(it, k) in options.items" @click="setsel(it)" :key="k*kkk"
            :class="`ff-category-item  color-${it.color} ${it.selected ? 'selected': ''}`">{{it.t}}</span>

    </div>
</template>

<style scoped>

</style>
<template>
    <div class="card card-body index p-0">
        <div class="top-button-group">
            <a href="/conversation-clubs" class="btn btn-light">Zobrazit rozvrh</a></div>

        <div class="d-flex">
            <div class="ml-auto p-2">
                <button class="btn btn-primary" @click.prevent="newLector"><span class="material-icons mia">add_circle</span> Přidat lektora</button>
            </div>
        </div>


<input autocomplete="email"   placeholder="Hledat" class="form-control" type="text"  style="display: none"/>
      <div class="filters d-flex">
        <div class="form-inline ml-auto">

          <div class="input-group ml-2">
            <div class="input-group-prepend">
              <div class="input-group-text"><svg class="svg-inline--fa fa-search fa-w-16"
                                                 aria-hidden="true" focusable="false" data-prefix="fas"
                                                 data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg><!-- <i class="fas fa-search"></i> --></div>
            </div>
            <input       placeholder="Hledat" class="form-control" type="search"  v-model="search_lector"/>
          </div>

        </div>
      </div>


      <OjPagination
          class="m-auto"
          :totalItems="total"
          :currentPage="page"
          :perPage="per"
          @pageChanged="pageChanged" />

        <table class="table">
            <thead>
            <tr>
                <th colspan="2" >Jméno</th>
                <!--<th style="width: 50%">Výuka</th>-->
                <th>Lekce/měsíc</th>
                <th></th>
            </tr>
            </thead>
            <tr v-for="l in lectors">
                <td>
                    <img :src="l.profile_image_url ? l.profile_image_url : '/img/peppa100.png'" class="user-ico"/>
                </td>
                <td style="width: 50%">{{l.first_name}}  {{l.last_name}}</td>
            <!--    <td>
                    <span :class="`badge badge-pill mr-2 level${level} s1`" v-for="level in l.kk_levels.split(',')" v-if="l.kk_levels.length>0">Level {{level}}</span>
                </td> -->
                <td>{{l.kk_lessons_count}}</td>
                <td><a href="#" @click.prevent="editLector(l)"><span class="material-icons-outlined">mode_edit_outline</span></a></td>

            </tr>

        </table>
      <OjPagination
          class="m-auto"
          :totalItems="total"
          :currentPage="page"
          :perPage="per"
          @pageChanged="pageChanged" />

        <modal-lector :user="lector" ref="modalLector" @userUpdated="onUserUpdated"></modal-lector>
    </div>

</template>

<script>
import axios from "axios";
import ModalLector from "./modal-lector.vue";
import OjPagination from "../OjPagination.vue";

export default {
  name: "cc-lectors",
  components: {OjPagination, ModalLector},
  data(){
    return {
      total:0,
      per:20,
      page:1,
        lectors: [],
        lector:{},
        search_lector:'',
      search_lector2:""
    }
  },

  mounted() {
    this.loadLectors()
  },

  watch:{
    search_lector(){
      this.search(this)
    }
  },


  methods:{
    pageChanged(p){
      this.page=p
      this.loadLectors()
    },

    search: _.debounce((vm) => {

      vm.loadLectors()

    }, 550),

      onUserUpdated(){
          this.loadLectors()
      },
    async loadLectors(){
      const r2 = await axios.get('/conversation-clubs/lectors.json',{
        params:{search: this.search_lector, per: this.per, page: this.page}
      })
      this.lectors = r2.data.items
      this.total = r2.data.total
      for(let i in this.lectors){
          if (this.lectors[i].kk_levels==null) this.lectors[i].kk_levels=''
      }

    },
    editLector(l){
        this.lector = l
        this.$refs.modalLector.showModal()
    },
    newLector(){
        this.lector = {}
        this.$refs.modalLector.showModal()
    }


  }
}
</script>

<style scoped>

.user-ico{
    width:30px;
    border-radius: 14px;
}
</style>
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["original", "list", "text", "selected"]

    connect() {
        console.log(`connected_with original: ${this.originalTarget}`)
        console.log(this.originalTarget)

        // create input
        const input = document.createElement("input")
        input.type = 'text'
        input.dataset['action'] = 'input->select#updateList blur->select#hideList focus->select#showList'
        input.dataset['target'] = 'select.text'
        input.setAttribute('placeholder', 'Vyhledávejte psaním') // TODO move prompt to data attribute
        this.element.appendChild(input)

        // create list
        const list = document.createElement("ol")
        list.classList.add('list')
        list.dataset['target'] = 'select.list'
        list.classList.add('hidden')
        this.element.appendChild(list)

        // create selected list
        const selected = document.createElement("ul")
        selected.classList.add('selected')
        selected.dataset['target'] = 'select.selected'
        this.element.appendChild(selected)

        // populate selected list
        const selectedOptions = this.originalTarget.querySelectorAll('option:checked')
        selectedOptions.forEach(option => {
            this.selectItem(option.innerText, option.value)
        })

        this.updateList()
    }

    hideList() {
        this.listTarget.classList.add('hidden')
    }

    showList() {
        this.updateList()
        this.listTarget.classList.remove('hidden')
    }

    updateList() {
        console.log("updating")
        this.listTarget.innerHTML = ''
        const text = this.textTarget.value.toLowerCase()

        // apparently this because JS is a piece of crap
        Array.prototype.map.call(this.originalTarget.options, option => [option.innerText, option.value]).filter(([str,val]) => {
            return (str.toLowerCase().indexOf(text) != -1)
        }).forEach(([str, val]) => {
            const item = document.createElement("li")
            item.innerText = str
            item.dataset['originalValue'] = val
            item.dataset['action'] = 'mousedown->select#addClickedItem' // click is too slow (text loses focus, list disappears)
            this.listTarget.appendChild(item)
        })
    }

    addClickedItem(e) {
        const clicked = e.target
        this.selectItem(clicked.innerText, clicked.dataset['originalValue'])
    }

    removeClickedItem(e) {
        const clicked = e.target
        this.deselectItem(clicked.dataset['originalValue'])
    }

    // private functions

    selectItem(text, value) {
        // select in original select
        const originalOption = this.originalTarget.querySelector(`option[value="${value}"]`)
        originalOption.setAttribute('selected', 'selected')

        // add to selected list
        const preexisting = this.selectedTarget.querySelector(`[data-original-value="${value}"]`)

        // skip if already selected
        if (!preexisting) {
            const item = document.createElement("li")
            item.innerText = text
            item.dataset['originalValue'] = value
            item.dataset['action'] = "mousedown->select#removeClickedItem"
            this.selectedTarget.appendChild(item)
        }

        this.textTarget.value = ''

    }

    deselectItem(value) {
        // deselect in original select
        const originalOption = this.originalTarget.querySelector(`option[value="${value}"]`)
        originalOption.removeAttribute('selected')

        // remove from selected list
        const selected = this.selectedTarget.querySelector(`[data-original-value="${value}"]`)
        selected.parentElement.removeChild(selected)
    }
}

import {VueFinalModal} from "vue-final-modal";
import moment from "moment/moment";

const modalKkMixin={

   data(){
    return({
      showM: false
    })
  },
  methods:{
     df(s){
       if (s=="" || s==undefined || s==null) return ""
        return moment(s).format('DD.MMMM YYYY')
     },
    dt(s){
      if (s=="" || s==undefined || s==null) return ""
      return moment(s).format('DD. MM. YYYY hh:mm:ss a')
    },
    showModal(){
      this.showM=true
    },
    hideModal(){
      this.showM=false
    }
  },
  components: {
    VueFinalModal
  }
}
export default modalKkMixin
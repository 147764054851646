<script>

import _ from "lodash";
import axios from "axios";
import ModalTemplates from "../paja/modal-templates.vue";
import OjPagination from "../OjPagination.vue";
import TranslatorEventStatusModal from "../paja/TranslatorEventStatusModal.vue";
import WordEditModal from "./word-edit-modal.vue";

export default {
  name: "vocabulary",
    components: {ModalTemplates, OjPagination, TranslatorEventStatusModal, WordEditModal},
  data(){
    return{
      working:false,
      edited_word:{},
      words:[],
      tk:111,
        courses:[],
        course:{},
        search:'',
        status: '',
        page: 1,
        total:0,
        per: 30,
        te:{},
        selected_course:{},
        active_template:{},
      all_ids:[],
      cb_ids:[],
      waiting_count:0,
      kolkoPages:[],
      event_detail:{}




    }
  },
  mounted() {
    this.loadCourses()
    this.loadWords()
  },
  watch:{
      status(){
        this.page=1
        this.loadWords()
      },

      per(){
          this.page=1
          this.loadWords()
      },

      search(){
          this.page=1
          this.searchWord(this.search, this)
      },
      course(){
          this.page=1
          this.te={}
          this.loadWords()
      }

  },


  methods: {
    handleCbChange(w){
      this.cb_ids=[]
      for( let i in this.words){
        if (this.words[i].cb) this.cb_ids.push( this.words[i].id  )
      }
    },

    pageChanged(p){
      this.page = p
      this.loadWords()
      this.cb_ids = []
    },
    get_kolkoPages(){
      const a=[]
      let n=1
      for(let i=0 ; i<this.total; i+=this.per){
        if (n<this.page+30 && n>this.page-20)     a.push(n)
        n+=1;
      }
      this.kolkoPages = a
      return a
    },

      searchWord: _.debounce(( search, vm) => {
          vm.loadWords()
      }, 450),

      async loadWords() {
          this.words = []
          this.cb_ids=[]
          const r2 = await axios.get('/oj/vocabulary/words.json', {
              params: { status: this.status, page: this.page , search: this.search, course_id: this.course, per: this.per }
          })
          this.words = r2.data.items

        for(let i in this.words){
          this.words[i].edit_mode=false
          this.words[i].key = this.words.id
          this.words[i].cb = false
        }
        this.tk++

            this.total = r2.data.total
          this.all_ids = r2.data.all_ids
        this.get_kolkoPages()
      },

      async loadCourses(){
          const r2 = await axios.get('/oj/courses.json', {
              params: { status: ['STATUS_APPROVED']}
          })
          this.courses = r2.data.items

      },

      paginateTo(p){
        this.page = p
        this.loadWords()
      },

    onSrcAlterChange(w){
      axios.put('/oj/vocabulary/tmp-alternative', {
            id: w.id,
            tmp_source_alternatives: w.tmp_source_alternatives
        })
    }
    ,

      onAlternChange(w){

        axios.put('/oj/vocabulary/tmp-alternative', {
            id: w.id,
            tmp_alternatives: w.tmp_alternatives
        })
      },
      onDescChange(w){
          axios.put('/oj/vocabulary/tmp-alternative', {
              id: w.id,
              tmp_description: w.tmp_description
          })
      },

      confirmAlters(w){
        axios.put('/oj/vocabulary/tmp-alternative-ok', {
            id: w.id
        }).then((resp) =>{
            w = resp.data
        })
      },

      translateAll(){
        this.post_translate(this.all_ids)
      },

      translateSelected() {
        let ids = []
        for (let i in this.words) {
            ids.push(this.words[i].id)
        }
        this.post_translate(ids)
      },

      translateCBSelected(){
        this.post_translate(this.cb_ids)
      },

      post_translate(ids){
          const data={
              ids: ids
          }
          this.working=true

          if ( confirm(`Opravdu chcete odeslat požadavek k doplnění alternativ k ${ids.length} slovíčkům?`)) {
            axios.post('/oj/vocabulary/translate', data).then((resp) => {
              //this.loadWords()
              this.$toasted.show("Spustil se překlad, výsledky se brzy objeví", {type: 'info'});
              this.translator_evnt_id = resp.data.translator_event.id
              setTimeout(this.getTranslatorStatus, 2000)
            })
          }
      },

      getTranslatorStatus(){
        if (!this.translator_evnt_id) return
          axios.get('/oj/vocabulary/translator_status', {params: {id: this.translator_evnt_id}}).then((resp) =>{

            this.te = resp.data.translator_event
            this.waiting_count = resp.data.waiting_count
              if (this.te.status=='created'){
                  setTimeout(this.getTranslatorStatus, 2000)
              }
              if (this.te.status=='done'){
                  this.working=false
                  this.$toasted.show("Překlad dokončen", {type: 'success'});
                  //this.status='ai_created'
                  this.waiting_count = 0
                  //this.loadWords()
                  this.refreshSelectedWords()
              }
          })
      },
      onCourseSelect(c){

          this.course = c.id

      },
      handleTplModal() {
          this.$refs.aiTemplatesModal.editting=false
          this.$refs.aiTemplatesModal.showModal()
      },

      detectTpl(tpl){
        //  console.log('detekt tpl', tpl.name)
          this.active_template = tpl
      },

    displayTranslInfo(w){
      axios.get('/oj/vocabulary/translator_status?rqlog=true', {params: {id: w.translator_event_id}}).then((resp) =>{
        this.event_detail = resp.data.translator_event
           this.$refs.display_te_status_modal.showModal()
      })
    },


    set_edit_mode(w){
      this.edited_word = w
      this.$refs.word_editor_modal.showModal()
    },

    async refreshSelectedWords(){
      let ids = []
      for (let i in this.words) {
        ids.push(this.words[i].id)
      }

      const r2 = await axios.post('/oj/vocabulary/words.json', {
        ids: ids.join(','),
        course_id: this.course
      })
      let words = r2.data.items
      for(let i in words){
        for(let z in this.words){
          if (this.words[z].id == words[i].id){
            this.words[z] = words[i]
            this.words[z].edit_mode=false

          }
        }
      }
      this.tk++
    }
  }
}
</script>

<template>
<div class=" card card-body">
    <modal-templates ref="aiTemplatesModal" v-on:detectTemplate="detectTpl" :kind="'vocabulary'"></modal-templates>
    <translator-event-status-modal :ev="event_detail" ref="display_te_status_modal"></translator-event-status-modal>
    <div class="d-flex">
        <div style="width:300px;">
            <v-select label="name" :filterable="true" :options="courses"  v-model="selected_course" placeholder="Vyber kurz" @input="onCourseSelect">
                <template #option="option">
                    <div class="d-flex">
                        <div style="min-width: 300px">{{ option.name }}</div>
                    </div>
                </template>
                <template #selected-option="option">
                    <div class="selected d-center">
                        {{ option.name }}
                    </div>
                </template>
            </v-select>

        </div>

        <div style="width:200px;">
            <select v-model="status" class="form-control">
                <option value=""> Nezpracované</option>
                <option value="ai_created">Přeložené AI</option>
                <option value="approved">Potvrzené lektorem</option>
                <option value="ai_failed">chyba</option>
            </select>
        </div>
        <div class="ml-3">
            <input type="text" placeholder="Hledej" v-model="search" class="form-control"/>
        </div>
        <div class="ml-3">
            <a @click.prevent="handleTplModal" href="#">nastavení AI dotazu</a><br>
            šablona dotazu: <b>{{active_template.name}}</b>
        </div>
        <div class="ml-3">
            Počet na stránce
            <select v-model="per" class="form-control w-auto">
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>

            </select>
        </div>

        <div class="ml-auto">
            Doplnit alternativy dle AI:<br>
          <button v-if="cb_ids.length" class=" m-1 btn2  btn-primary" @click.prevent="translateCBSelected">Doplnit u vybraných ({{cb_ids.length}})</button>
          <button class=" btn2  btn-primary m-1" @click.prevent="translateSelected">Doplnit u zobrazenych ({{words.length}})</button>
          <button class=" btn2 btn-primary m-1" @click.prevent="translateAll">Doplnit u všech vyfiltrovaných ({{all_ids.length}})</button>


        </div>
      <div>
        <button class="btn2 btn-outline-dark" @click.prevent="refreshSelectedWords">Obnovit</button>
      </div>
    </div>

    <div class="py-2" >
        <div class="alert alert-danger" v-if="te.status=='failed'">
            {{te.message}}
        </div>
    </div>

    <div v-if="waiting_count>0">na překlad čeká {{waiting_count}}</div>

    <OjPagination
          :totalItems="total"
          :currentPage="page"
          :perPage="per"
          @pageChanged="pageChanged" class="mt-2"/>

    <word-edit-modal ref="word_editor_modal" :word="edited_word" @onCancel="edited_word={}"></word-edit-modal>


    <div class="text-center" v-if="working">
      <img src="/img/1495.gif"  />
    </div>

    <table class=" table table bordred fixTableHead">
        <thead>
            <tr class="row1">
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>ID</th>
                <th>Slovo</th>
                <th>Překlad</th>

                <th>Alternativy navržené Pájou</th>
                <th>Navržený popis</th>
                <th>Alternativy v jazyce originálu</th>
                <th>&nbsp;</th>
                <th colspan="2"></th>
            </tr>
            <tr class="extrarow">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&lt;id&gt;</td>
              <td>&lt;source&gt;</td>
              <td>&lt;translation&gt;</td>

              <td>&lt;translation_alt&gt;</td>
              <td>&lt;description&gt;</td>
              <td>&lt;source_alt&gt;</td>


              <td colspan="2"></td>
            </tr>
        </thead>
        <tbody :key="tk">
            <tr v-for="w in words" >
              <td><input type="checkbox" v-model="w.cb" @change="handleCbChange(w)"/></td>
              <td><a href=# @click.prevent="set_edit_mode(w)"><span class="material-icons-outlined">mode_edit_outline</span></a></td>
                <td>{{w.id}}</td>
                <td>

                    {{w.source}}
                    <div class="alternatives">{{w.word_alternatives}}</div>


                </td>
                <td>{{w.translation}}
                  <div class="alternatives">{{w.alternatives}}</div>
                </td>

                <td class="w-25">
                    <textarea  v-model="w.tmp_alternatives" class="form-control pajaarea" @change="onAlternChange(w)"/>
                </td>
                <td class="w-25">
                    <textarea v-model="w.tmp_description" class="form-control pajaarea" @change="onDescChange(w)" rows="3"/>
                </td>
              <td class="w-25">
                    <textarea  v-model="w.tmp_source_alternatives" class="form-control pajaarea" @change="onSrcAlterChange(w)"/>
                </td>

                <td>
                    <button v-if="w.ai_process_status!=1" class="btn btn-small" @click.prevent="confirmAlters(w)">Potvrdit</button>
                </td>
              <td>
                <a href="#" @click.prevent="displayTranslInfo(w)" :class="`${w.translator_event_id>0 ? '' : 'disabled'}`">info<small>({{w.translator_event_id}})</small></a>
              </td>
            </tr>
        </tbody>
    </table>


  <p> &lt;id> označení slovíčka v databázi studovny</p>

  <p>&lt;source> zdrojové slovíčko v cizím jazyce (např. anglické slovíčko)</p>

  <p>&lt;translation> překlad slovíčka do vyučovacího jazyka (např. překlad do češtiny)</p>

  <p>&lt;translation_alt> alternativy k překladu slovíčka ve vyučovacím jazyce (např. české alternativy)</p>

  <p>&lt;description> popis překladu slovíčka, ale v cílovém jazyce zdrojového slovíčka
    (např. popis českého překladu slovíčka, ale v angličtině - bude se zobrazovat místo českého překladu u vyšších úrovní kurzu)</p>

  <p>&lt;source_alt> alternativy ke zdrojovému slovíčku v cílovém jazyce (např. anglické alternativy)</p>




</div>
</template>

<style scoped lang="scss">

.fixTableHead thead th {
  position: sticky;
  top: 0;
  background: white;
}
.fixTableHead {
      overflow-y: auto;
      height: 110px;
    }

.alternatives{
  color:#558;
  font-style: italic;
}


.paginator{
    a{
        padding: 0 10px;
        border-right: 1px solid silver
    }
    .active{
        font-weight: bold;
    }

}


.extrarow{
  td{
    border-top: 0;
    padding-top: 0;
  }
}
.row1{
  th{
    text-transform: uppercase;
    font-weight: bold;
  }
}

.pajaarea{
  padding:2px;
  line-height: 1.1em;
}
</style>
<template>
    <div class="card card-body index p-0">
        <div class="top-button-group">
            <a href="/oj/courses" class="btn btn-light">Kurzy</a></div>

        <div class="d-flex">
            <div class="ml-auto p-2">
                <button class="btn btn-primary" @click.prevent="newCourse"><span class="material-icons mia">add_circle</span> Přidat kurz</button>
            </div>
        </div>

        <table class="table">
            <thead>
            <tr>
                <th>Název</th>
                <th>Trh/Zdrojový jazyk</th>
                <th>Cílový jazyk</th>
                <th>Stav</th>
                <th></th>
            </tr>
            </thead>
            <tr v-for="l in courses">
                <td>
                  {{l.name}}
                </td>
                <td>{{l.type}}</td>
                <td>{{l.target}}</td>
                <td>{{l.status}}</td>
                <td><a href="#" @click.prevent="editCourse(l)"><span class="material-icons-outlined">mode_edit_outline</span></a></td>
            </tr>

        </table>

    </div>

</template>

<script>
import axios from "axios";

export default {
  name: "courses",
  components: {},
  data(){
    return {
        courses: [],
        course:{}
    }
  },

  mounted() {
    this.loadCourses()
  },
  methods:{
      onCourseUpdated(){
          this.loadCourses()
      },
      async loadCourses(){
        const r2 = await axios.get('/oj/courses.json',{ params:{}})
        this.courses = r2.data.items
        //for(let i in this.courses){
        //    if (this.courses[i].status==null) this.courses[i].kk_status=''
        //}

      },
      editCourse(l){
          this.course = l
          //this.$refs.modalCourse.showModal()
      },
      newCourse(){
          this.course = {}
          //this.$refs.modalCourse.showModal()
      }

  }
}
</script>

<style scoped>

.user-ico{
    width:30px;
    border-radius: 14px;
}
</style>
<template>
  <div class="text-center">
      <div class='noimage' v-if="src2==null || src2==''"> obrazek neni</div>
      <img v-else :src="src2" style="max-width: 150px;display: block; margin: auto"/>
      <input type="file" name="add-file-ico" id="add-file-ico" v-on:change="handleUploadImage($event)" ref="pinimagefile">
      <button class="btn btn-sm btn-primary" @click.prevent="handleClickF"><span class="material-icons  mia">add_circle</span> Nahrát nový</button>
  </div>
</template>

<script>
import axios from "axios";
//       import {Cropper} from "vue-advanced-cropper";

export default {
  name: "profile-image",
  props:['src'],
  watch:{
    src(){
      this.src2 = this.src
    }
  },
  data(){
    return ({
      src2:''
    })
  },
  methods:{

    handleClickF(){
      this.$refs.pinimagefile.click()
    },

    handleUploadImage(e) {
      let f = e.target.files[0]

      this.$emit('imageChange', f)
      this.src2 = URL.createObjectURL(f)
/*
      const formData = new FormData()
      //formData.append('page_id', this.page_id)
      formData.append("file", f, f.name)
      axios.post('/users/profile_image', formData)
          .then(response => {
            if (response.status==200){
              ///this.reloadImages()

            }
      })

 */
    },

  }
}
</script>

<style scoped>
#add-file-ico{ visibility: hidden; height:0; width: 100%;}
.noimage{
  border: 3px solid #eee;

  display: block;
  height:110px;
  width:110px;
  margin: auto;
  background: white;
  line-height: 90px;
  text-align: center;
}
</style>
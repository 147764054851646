<script setup>

import {VueFinalModal} from "vue-final-modal";
import FUsers from "../fields/FUsers.vue";
export default {
  name: "FGUsers",
  props: ['options'] ,
  components: {
    VueFinalModal, FUsers
  },
  data(){
    return({
      showM: false
    })
  },
  methods:{
    hideModal(){
      this.showM=false

    }
  }
}

</script>

<template>
<div>
  <FUsers :options="options" :item="{}" />

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f mcw1">

    <div class="modal-header">
      <span class="modal__title fg-modal-title">Vlastnosti prvku</span>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal__content" >
      <label>Label</label>
      <input type="text" class="form-control" v-model="options.label"/>

    </div>
  </vue-final-modal>

</div>
</template>

<style scoped>

</style>
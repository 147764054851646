<script>
import './fe.scss'
import FGInputText from "./fields-editor/FGInputText.vue";
import FGInputDate from "./fields-editor/FGInputDate.vue";
import FGInputCategory from "./fields-editor/FGInputCategory.vue";
import FGUsers from "./fields-editor/FGUsers.vue";
import axios from "axios";
import RemoveField from "./RemoveField.vue";
import NewApp from "./new-app.vue";
export default {
  data(){
    return{
      app:{name: 'first app'},
      apps:[],
      fields:[
          {type: 'text', label:'prdel'},
          {type: 'category', label:'KAtgorie', items: [{t:'Alfa', color: 'D2E4EB'}, {t:'Betha', color: 'F3F3F3'}]},
      ],

      showM:false,
      vv:'table'
    }
  },
  components:{FGInputText, FGInputDate, FGInputCategory, RemoveField, FGUsers, NewApp},
  mounted() {
    //
    this.loadApps()
  },
  methods:{
    loadApps(){
        axios.get(`/formgenerator/forms`).then(response=>{
          this.apps = response.data.apps

          const h = window.location.hash.substring(1)
          console.log('hhh', h)
          for (let i in  this.apps){
            if (this.apps[i].codename == h){
              this.handleEditApp(this.apps[i])
              break;
            }
          }


        })
    },
    async handleEditApp(a){
      await this.loadFormApp(a.id)
      this.vv='editor'
    },

    addField(type){
      const d = {type: type, label:`label ${type}`}

      if (type=='date'){
        d.enable_end_date = false
        d.use_time = true
      }

      if (type=='category'){
        d.items = []
      }

      if (this.fields==null) this.fields = []
      this.fields.push(d)

    },

    loadFormApp(id){
      axios.get(`/formgenerator/forms/${id}`).then(response=>{
        this.fields = response.data.fields
        this.app = response.data.app


        // codename
        for(let i in this.fields){
          if ( this.fields[i].codename==undefined){
            this.fields[i].codename = this.create_codename(this.fields[i])
          }
        }

      })
    },
    create_codename(field){
      let  f = field.label.toLowerCase().trim();
      f = f.replace(/\s/, '_')
      return f
    },
    saveChanges(){


// codename
      for(let i in this.fields){
        if ( this.fields[i].codename==undefined){
          this.fields[i].codename = this.create_codename(this.fields[i])
        }
      }

      const data={
        app: {
          name: this.app.name,
          codename: this.app.codename,
          fields_helper: this.fields
        }
      }

      if (this.app.id>0){
         axios.put(`/formgenerator/forms/${this.app.id}`, data)
      }else{

        axios.post('/formgenerator/forms', data)
      }

    },

    handleEditField(k){
      console.log('edit k', k, this.$refs[`field_${k}`])
      this.$refs[`field_${k}`][0].showM=true
    },
    removeField(f){
      this.$refs.removeField.showModal()
    },
    btnNewApp(){
      this.$refs.dialogNewApp.showModal()
    },
    onCreateApp(app){
      this.app  = app
      console.log('new app ', app)
      this.fields=[]
      this.vv='editor'

    }

  }

}

</script>

<template>
<div class="big-blok">
  <NewApp ref="dialogNewApp" @create="onCreateApp"/>

  <div v-if="vv=='editor' && app">
    <div class="row">
      <div class="col-md-12 mb-3">
        Aplikace: #{{app.id}} {{app.name}} / {{app.codename}} <a :href="`/formgenerator/app/${app.codename}`">open {{app.name}}</a>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12 ">
        <input type="text" v-model="app.name" class="form-control big"/>
      </div>
    </div>


    <div class="row">
    <div class="col-md-2">
      <h5>Fields</h5>
      <ul class="field-types">
        <li>
          Text
          <a class="b1" href="#" @click.prevent="addField('text')">add</a>
        </li>
        <li>
          Date
          <a class="b1" href="#" @click.prevent="addField('date')">add</a>
        </li>
        <li>
          Kategorie
          <a class="b1" href="#" @click.prevent="addField('category')">add</a>
        </li>
        <li>
          User
          <a class="b1" href="#" @click.prevent="addField('user')">add</a>
        </li>
      </ul>


    </div>

    <div class="col-md-10">

      <div v-for="(f, k) in fields" class="d-flex">
          <div class="ec1"> {{k}}
            <FGInputText :options="f" v-if="f.type=='text'" :ref="`field_${k}`"></FGInputText>

            <FGInputDate :options="f" v-if="f.type=='date'" :ref="`field_${k}`"></FGInputDate>

            <FGUsers :options="f" v-if="f.type=='user'" :ref="`field_${k}`"></FGUsers>

            <FGInputCategory :options="f" v-if="f.type=='category'" :ref="`field_${k}`"></FGInputCategory>
          </div>
          <div class="ec2">
            <a href="#" @click.prevent="handleEditField(k)"><span class="material-icons-outlined">mode_edit_outline</span></a><br>
            <a href="#" @click.prevent="removeField(f)" class="delete"><span class="material-symbols-outlined">delete</span></a>
          </div>
      </div>

      <RemoveField ref="removeField"></RemoveField>

<hr>
      <button class="btn btn-outline-success" @click.prevent="saveChanges">Ulozit zmeny</button>
      <pre>

{{fields}}
      </pre>


    </div>

  </div>
    <div class="row">
      <div class="col-md-12">
        <a href="/formgenerator/editor/index">Seznam Aplikací</a>
      </div>
    </div>
  </div>
  <div v-else>
    <h4>Sznam aplikacii</h4>
    <table class="table">
      <tr>
        <th>ID</th>
        <th>CodeName</th>
        <th>Name</th>
        <th></th>
      </tr>
      <tbody>
        <tr v-for="a in apps">
          <td>{{a.id}}</td>
          <td>{{a.codename}}</td>
          <td>{{a.name}}</td>
          <td><a @click="handleEditApp(a)" :href="`#${a.codename}`"><span class="material-icons-outlined">mode_edit_outline</span></a></td>
        </tr>
      </tbody>
    </table>

    <button class="btn2 btn-outline-info" @click="btnNewApp">Přidat novou</button>
  </div>
</div>
</template>

<style scoped>
.ec1{
  width:90%
}
.ec2{
  width: 100px;
  background: #f0f0f0;
}

.ec1,.ec2{
  border-bottom: 2px solid #6ec0ff40;
  padding:15px 0
}



</style>
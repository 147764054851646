//import StudentReport from '../components/report.vue'
import StudentsTableDetail from '../components/StudentsTableDetail.vue'
import WooApiLog from '../components/woo-api-log'
import StudentAssignments from "../components/StudentAssignments";
import OrderStatusUpdate from "../components/order-status-update";
import SelectStudent from "../components/select-student";
import OrderItems from "../components/order-items";

import Vue from 'vue/dist/vue.esm'
import vmodal from 'vue-js-modal'
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import Toasted from 'vue-toasted';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import SelectProduct from "../components/select-product";
import RefundDialog from "../components/refund-dialog";

import PaymentSubtotals from "../components/payment-subtotals";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import OrganizationHelpers from "../components/OrganizationHelpers";

import WooLog2 from "../components/woo-log2";

import SystemStatistics from "../components/system-statistics";

import TestSender from "../components/TestSender";
import TypeformAcOptions from "../components/typeform-ac-options";

import OrganizationStudents from "../components/organization-students";

import NotifyFilters from '../components/notifications/filters.vue'
import axios from "axios";

import Marketing from "../components/marketing.vue";
import VueTabs from 'vue-nav-tabs/dist/vue-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

import Ojsyncshow from "../components/oj-sync-show"
import AppSubscrbtionChnages from "../components/app-subscrbtion-chnages.vue";

import CcCalendar from "../components/conversation-club/cc-calendar.vue";
import CcTopics from "../components/conversation-club/cc-topics.vue";

import '../components/conversation-club/kk.scss'
import CcStudents from "../components/conversation-club/cc-students.vue";
import CcLectors from "../components/conversation-club/cc-lectors.vue";

import OjCourses from "../components/oj/courses.vue";

import 'vue-material-design-icons/styles.css';
import ShoppingCart from "../components/shopping-cart/shopping-cart.vue";
import Openai from "../components/paja/openai.vue";
import DiscountCodes from "../components/DiscountCodes.vue";
import PajaRequestlog from "../components/paja/paja-requestlog.vue";
import PartnersImport from "../components/pertners/partnersImport.vue";
import Vocabulary from "../components/paja/vocabulary.vue";

import CoursesTranslator from "../components/paja/courses-translator.vue";
import AppLektorProfil from "../components/conversation-club/app-lektor-profil.vue";
import AppKkLessons from "../components/conversation-club/app-kk-lessons.vue";
import FgEditor from "../components/form-generator/fg-editor.vue";
import FormGenerarorApp from "../components/form-generator/FormGenerarorApp.vue";

import Orders from "../components/tables/orders.vue";
import DevicesTokens from "../components/devices-tokens.vue";

import AppAcListLog from "../components/tables/app-ac-list-log.vue";
import StudentDevicesTokens from '../components/student-devices-tokens.vue'

Vue.use(VueTabs)



function add_vueApp(id, components={}){
  if (document.getElementById(id)){
    const a = new Vue({
      components: components
    })
    a.$mount('#'+id);
  }
}

function add_vueApp2(id , component){
  const ele = document.getElementById(id)
   if (ele){
      const a = new Vue(component)
     for(let i in ele.dataset){
       a[i] = ele.dataset[i]
     }
      a.$mount('#'+id);
    }
}


window.addEventListener('load', () => {
  Vue.use(Toasted, {
    position: "top-center",
    duration : 10000,
    type: 'success'
  });
});
Vue.use(vmodal)
Vue.component('v-select', vSelect)
Vue.use(VueCustomTooltip, {
  name: 'VueCustomTooltip',
  color: '#fff',
  background: '#101040',
  borderRadius: 5,
  fontWeight: 400,
})



 document.addEventListener('DOMContentLoaded', () => {
   //const app = new Vue({el: '#reports-app',  components: { StudentReport } })


   if (document.getElementsByClassName('student-detailinfo').length>0) {
     const dapp = new Vue({
       components: {StudentsTableDetail}
     })
      const vm = dapp.$mount('#student-detail-app');

      $('.student-detailinfo').click(function(){
        var el = $(this)[0]
        console.log(el)
         if (el.dataset){
            //console.log(el.dataset.student, vm)
            var d= JSON.parse(el.dataset.student)
            vm.$refs.dc.show_student_modal(d)
         }
      })
   }


   const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
   axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
   axios.defaults.withCredentials=true


   add_vueApp('order-actions', {RefundDialog})

   add_vueApp('app-aorganization-buttons', {OrganizationHelpers})

   //add_vueApp('app-orders', {WooLog2})

   add_vueApp2('app-orders2', WooLog2)

   add_vueApp2('app-subscrbtion-chnages', AppSubscrbtionChnages);
   add_vueApp2('app-kk-calendar', CcCalendar)

   add_vueApp2( 'app-kk-topics', CcTopics)
   add_vueApp2('app-kk-students', CcStudents)

   add_vueApp2('app-kk-lectors', CcLectors)

   add_vueApp2('app-oj-courses', OjCourses)

   add_vueApp2('shopping-cart-app', ShoppingCart)
   add_vueApp2('openai-app', Openai)

   add_vueApp2('app-discount-codes', DiscountCodes)


   add_vueApp2('openai-app-requestlog', PajaRequestlog)


   add_vueApp2('partners-import-app', PartnersImport)

add_vueApp2('app-lektor-profil', AppLektorProfil)

   add_vueApp2('app-oj-paja-courses', CoursesTranslator)


   add_vueApp2('app-oj-vocabulary', Vocabulary )


   add_vueApp2('app-kk-lessons', AppKkLessons)


   add_vueApp2('app-formgen1', FgEditor)

    add_vueApp2('app-formgen-app', FormGenerarorApp)

   add_vueApp2('app-table-orders-app', Orders)

   add_vueApp2('app-devices-tokens', DevicesTokens)

   add_vueApp2('app-ac-list-log', AppAcListLog)

   add_vueApp2('app-student-devices', StudentDevicesTokens)



   add_vueApp('sidebar-infopanel', {SystemStatistics})

   add_vueApp('organization-show-card-app',{TestSender, OrganizationStudents})

   add_vueApp('typeform-ac-options-app', {TypeformAcOptions})

   add_vueApp('student-kurzy-app', {StudentAssignments, Ojsyncshow})

   add_vueApp('vue-woo-api-log', {WooApiLog})

   add_vueApp('payments-show-app', {PaymentSubtotals})


   add_vueApp('app-notify-filter', {NotifyFilters})

   add_vueApp( 'app-marketing', {Marketing})

   const vue_form_ord = document.getElementById('vue-form-order')
  if (vue_form_ord){
      const app4 = new Vue({
        components: {OrderStatusUpdate, SelectStudent, OrderItems,datePicker},
        data(){
          return {
            status: null,
            total_price: null,
            payment:{},
            created_on:'',
            paid_on:'',

            pickerconfig:{
              locale:'cs'
            }

          }
        },
        mounted() {
          var d = vue_form_ord.dataset.payment
          if (d){
            this.payment = JSON.parse(d)

            if (this.payment.created_on) this.created_on = new Date(this.payment.created_on)
            if (this.payment.paid_on) this.paid_on = new Date(  this.payment.paid_on)
            this.total_price = this.payment.total
          }
        }
      })
      app4.$mount('#vue-form-order');
   }




  const subscription_div = document.getElementById('vueapp-subscription-form')
   if (subscription_div){
      const app5 = new Vue({
         components: { SelectStudent, SelectProduct },
        data(){
           return {
             sub:{},
             product:{}
           }
        },
        mounted(){
           this.sub = JSON.parse(subscription_div.dataset.subscription)

        },
        watch:{
          'sub.months':  'changeM'
        },
        methods:{

          changeM(){
            if (this.product && this.product.id){
              this.onProductSelect(this.product)
            }
          },
          onProductSelect(selected_product){
            this.product = selected_product
            let inp = document.getElementById('subscription_payments_attributes_0_total')
            console.log('sel cen', this.sub.months, selected_product)
            let m = parseInt( this.sub.months )
            let price_name = 'price_monthly'
            if (m == 3) price_name = 'price_3months'
            if (m == 6) price_name = 'price_6months'
            if (m == 12) price_name = 'price_yearly'

            let price = selected_product[`${price_name}_czk`]
            if (!price) price = selected_product[`${price_name}_eur`]

/*
* price_3months_czk: "799.0"
price_3months_eur: null
price_6months_czk: "1400.0"
price_6months_eur: nul
price_czk: "6999.0"
price_eur: null
price_monthly_czk: "299.0"
price_monthly_eur: null
price_yearly_czk: "2100.0"
price_yearly_eur: null*/

            inp.value = price

          }
        }
      })
      app5.$mount(subscription_div);
   }

 })






//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })

<template>

    <div class="card">
      <div class="card-body">

      <table class="table table-bordered">
        <tr v-for="s in items" :key="s.id">
          <td>{{s.level}}</td>
          <td>{{s.komunikacni_jazyk}}</td>
          <td>{{s.cilovy_jazyk}}</td>
          <td>{{s.jazykova_uroven_slovne }}</td>
          <td>{{s.jazykova_uroven_doporuceny_kurz }}</td>
          <td><button @click="editScenario(s)" class="btn  btn-small">upravit</button></td>
        </tr>
      </table>

      <button @click="newScenario">Přidat </button>


      <modal name="tf-ac-option-modal" height="auto" adaptive :draggable="true"  width="900">
        <div class="modal-header">
          <h5 class="modal-title">Nastavení</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label>jazyková úroveň slovně</label>
            <input type="text" v-model="detail.jazykova_uroven_slovne" class="form-control"/>
          </div>

          <div class="form-group">
            <label>Doporučený kurz</label>
            <input type="text" v-model="detail.jazykova_uroven_doporuceny_kurz" class="form-control"/>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Komunikační jazyk</label>
                <select type="text" v-model="detail.komunikacni_jazyk" class="form-control">
                  <option value="aj">AJ</option>
                  <option value="cj">CJ</option>
                  <option value="sj">SJ</option>
                  <option value="nj">NJ</option>
                  <option value="fj">FJ</option>
                  <option value="sk">SK</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Cílový jazyk</label>
                <select type="text" v-model="detail.cilovy_jazyk" class="form-control">
                  <option value="aj">AJ</option>
                  <option value="cj">CJ</option>
                  <option value="sj">SJ</option>
                  <option value="nj">NJ</option>
                  <option value="fj">FJ</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Dosažená úroveň</label>
                <select type="text" v-model="detail.level" class="form-control">
                  <option >A0</option>
                  <option >A1</option>
                  <option >A2</option>
                  <option >B1</option>
                  <option >B2</option>
                  <option >C1</option>
                  <option >C2</option>
                </select>
              </div>
            </div>

          </div>








        </div>
        <div class="modal-footer">
          <button @click="handleCancel" class="btn btn-smallish">Zrušit</button>
          <button @click="handleOk" class="btn btn-outline-success">OK</button>
        </div>
      </modal>


      <pre>{{errors}}</pre>
      </div>
    </div>

</template>

<script>
/*
 *                                api_typeform_options GET      /api/typeform_options(.:format)                                                             api/typeform_options#index
 *                                                     POST     /api/typeform_options(.:format)                                                             api/typeform_options#create
 *                                 api_typeform_option PATCH    /api/typeform_options/:id(.:format)                                                         api/typeform_options#update
 *                                                     PUT      /api/typeform_options/:id(.:format)                                                         api/typeform_options#update
 *                                                     DELETE   /api/typeform_options/:id(.:format)                                                         api/typeform_options#destroy*/
import axios from "axios";

export default {
  name: "typeform-ac-options",
  computed: {

  },
  data(){
    return({
      errors:{},
      items:[],

      detail:{},
    })
  },

  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
    this.loadItems()
  },
  methods:{
    newScenario(){
      this.detail = {}

      this.$modal.show('tf-ac-option-modal')
    },
    editScenario(s){
      this.detail = s
      this.chronos = s.chronos
      this.htmlForEditor = s.content
      this.$modal.show('tf-ac-option-modal')
      this.errors={}
      console.log('ED', this.detail.id)
    },

    createItem(data){


      axios.post(`/api/typeform_options`, data).then(response => {
        this.detail = {}
        this.htmlForEditor = ''

        this.loadItems()
        this.$modal.hide('tf-ac-option-modal')
      }).catch(err => {

        //this.$toast.error('Chyba')
        this.errors = err.response.data

      })
    },
    updateItem(data){
      axios.put(`/api/typeform_options/${data.options.id}`, data).then(response => {
        this.detail = {}

        this.$modal.hide('tf-ac-option-modal')
        this.loadItems()
      }).catch(err => {
        console.log('EEE', err)
        //this.$toast.error('Chyba')
        this.errors = err.response.data

      })
    },
    loadItems(){


      axios.get("/api/typeform_options", ).then(response => {
        this.items = response.data.items
      })
    },

    resetModal(){},

    handleCancel(){
      this.detail = {}
      this.$modal.hide('tf-ac-option-modal')
    },

    handleOk(bvModalEvent){
      bvModalEvent.preventDefault()
      console.log('modal OK',this.detail.id )

      const data= {
        options: this.detail
      }

      if (this.detail.id!=undefined && this.detail.id>0){
        this.updateItem(data)
      }else{
        this.createItem(data)
      }
    }

  }
}
</script>

<style scoped>

</style>



<script setup>
export default {
  name: "DevicesTokens",
  components:{  },
  props:['filter'],
  data(){
    return{
      dod:'',
      ddo:''
    }
  },
  methods:{

  },
  watch:{
    ddo(){
      this.$emit('change', this.filter, this.dod, this.ddo)
    },
    dod(){
      this.$emit('change', this.filter, this.dod, this.ddo)
    }
  }
}
</script>

<template>
  <div class="form-group">
    <label>{{filter.label}}</label>
    <div class="d-flex">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">od </span>
        </div>
        <input type="date" class="form-control"
               v-model="dod" />
      </div>
      <div class="input-group ml-2">
        <div class="input-group-prepend">
          <span class="input-group-text">do </span>
        </div>
        <input type="date" class="form-control"
               v-model="ddo" />
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>
import { render, staticRenderFns } from "./new-app.vue?vue&type=template&id=65125b69&scoped=true&"
import script from "./new-app.vue?vue&type=script&setup=true&lang=js&"
export * from "./new-app.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65125b69",
  null
  
)

export default component.exports
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['destroy', 'destroyBundle'];

  connect() {
    this.state = 0;
  }

  toggle(event) {
    const button = event.currentTarget;
    const checkboxes_destroy = this.destroyTargets;
    const checkboxes_destroy_bundle = this.destroyBundleTargets;

    const tooltip = button.attributes['aria-describedby'];
    if (tooltip) {
      $(button).tooltip('hide');
    }
    let buttonText = null;
    switch (this.state) {
      case(0):
        checkboxes_destroy_bundle.forEach(el => el.checked = true);
        this.state += 1;
        buttonText = 'Označit kurzy';
        break;
      case(1):
        checkboxes_destroy_bundle.forEach(el => el.checked = false);
        checkboxes_destroy.forEach(el => el.checked = true);
        this.state += 1;
        buttonText = 'Označit vše';
        break;
      case(2):
        checkboxes_destroy.forEach(el => el.checked = true);
        checkboxes_destroy_bundle.forEach(el => el.checked = true);
        this.state += 1;
        buttonText = 'Zrušit označení';
        break;
      case(3):
        checkboxes_destroy.forEach(el => el.checked = false);
        checkboxes_destroy_bundle.forEach(el => el.checked = false);
        this.state = 0;
        buttonText = 'Označit balíčky';
        break;
    }

    button.innerHTML = `
        <i class="material-icons float-right">fact_check</i> ${buttonText}
    `
  }
}

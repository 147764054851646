<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">
    <div class="modal-header">
      <b>Přidat existující rozvrh</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>



      <table class="table table-striped ">
          <tbody>
          <tr>
              <td>Kopie předchozího týdne</td>
              <td><a @click.prevent="useCloneWeek" href="#">použít</a></td>
              <td></td>
          </tr>

          <tr  v-for="t in tpls" :value="t.id">
              <td><b>{{t.name}}</b></td>
              <td><a @click.prevent="useTemplate(t)" href="#">použít</a></td>
              <td><a @click.prevent="destroyTemplate(t)" href="#"><span class="material-symbols-outlined">delete</span></a></td>
          </tr>
          </tbody>
      </table>





    <div class="modal__action">
     <!-- <button  class="btn btn-primary" @click="useTemplate">načíst rozvrh</button>-->
      <button class="btn btn-outline-dark ml-4" @click="showM=false">zrušit</button>
    </div>

  </vue-final-modal>
</template>

<script>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";

export default {
  name: "modal-load-template",
  props: ['week'],
  data(){
    return({
      showM: false,
      tpls: [],
      selected_tpl:{}
    })
  },
  components: { VueFinalModal },
  methods:{
    showModal(){
      this.showM=true
      this.loadTemplates()
    },

    async loadTemplates(){
      const response = await axios.get('/conversation-clubs/templates.json')
      this.tpls = response.data.templates
    },

    useTemplate(t){
      axios.post('/conversation-clubs/use-template', {
        template_id: t.id,
        week: this.week.format('YYYY-MM-DD')
      }).then(response => {
        this.showM=false
        this.$emit('select', response.data.created)
      })
    },

    useCloneWeek(){
      axios.post('/conversation-clubs/use-week-clone', {
        week: this.week.format('YYYY-MM-DD')
      }).then(response => {
        this.showM=false
        this.$emit('select', response.data.created)
      })

    },

    destroyTemplate(t){
      if (confirm(`Opravdu samzat "${t.name}"?`)) {

        axios.delete(`/conversation_club/destroy_template/${t.id}`).then(response => {
          this.loadTemplates()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
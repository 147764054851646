<script setup>
export default {
  name: "FInputDate",
  props: ['options', 'item']
}
</script>

<template>
<div class="form-group">
    <label>{{options.label}}  </label>
    <input type="date" class="form-control" v-model="item[options.codename]"/>
</div>
</template>

<style scoped>

</style>
<template>
  <vue-final-modal v-model="showModalSaveTemplate" classes="modal-container" content-class="modal-content-f">
    <div class="modal-header">
      <b>Uložit rozvrh jako vzor</b>
    </div>


    <label>název šablony</label>
    <input type="text" v-model="templateName" class="form-control"/>


    <div class="modal__action">
      <button  class="btn btn-primary" @click="saveTemplate">uložit šablonu</button>
      <button class="btn btn-outline-dark ml-4" @click="showModalSaveTemplate=false">zrušit</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import axios from "axios";
export default {
  props: ['lessons', 'week'],
  components: { VueFinalModal },
  name: "modal-save-template",
  data(){
    return({
      showModalSaveTemplate: false,
      templateName:'',
    })
  },
  methods:{
    showModal(){
      this.showModalSaveTemplate=true
    },
    saveTemplate(){
      /*
      * {
	"0": {
		"capacity": 5,
		"conversation_club_topic_id": 1,
		"created_at": "2023-03-20T19:18:14.522+01:00",
		"day": "2023-03-21",
		"hour": 13,
		"id": 8,
		"level": 2,
		"name": "Lekce",
		"native_speaker": null,
		"students": [],
		"students_count": 1,
		"topic_name": "Seznamování a představování / Introducing yourself",
		"updated_at": "2023-03-21T15:53:32.822+01:00"
	}
}*/

      const arr=[]
      for (var i in this.lessons){
        const  l = this.lessons[i]
        //pri ukladani sablony uložit jen lektory a levely, pri nacteni sablony nacist to samé ale neumoznit zverejneni nez se vyberou temata
        arr.push({
          wday: moment(l.day).day(),
          hour: l.hour,
          level: l.level,
          capacity: l.capacity,
          lector_id: l.lector_id,
          native_speaker: l.native_speaker
        })
      }

      

      const data= {
        original_day: this.week,
        name: this.templateName,
        lessons: arr,
        //l1: this.lessons
      }
      axios.post('/conversation-clubs/update-template', data).then( response =>{
        this.$toasted.show("Uloženo", {type: 'success'});
        this.showModalSaveTemplate=false
        this.templateName=''
      }).catch(err => {
        this.$toasted.show(`Chyba: ${err.response.data.errors.join('<br>')}`, {type: 'error'});

      })

    }
  }
}
</script>

<style scoped>

</style>
<template>
    <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
        <div class="modal-header">
            <b>Detail Kodu  #{{code.id}}</b>
            <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal__content">
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Název kódu</label>
                        <input type="text" class="form-control" v-model="code.name"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Důvod vytvoření kódu:</label>
                        <select class="form-control" v-model="code.reason">
                            <option v-for="(v,k) in reasons_select" :value="k">{{v}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Poznámka ke kódu</label>
                        <textarea class="form-control" v-model="code.note"></textarea>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Výše slevy %</label>
                        <input type="text"  class="form-control" v-model="code.discount_percent"/>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Výše slevy částka:</label>
                        <input type="text"  class="form-control" v-model="code.discount"/>

                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Výše slevy částka:</label>
                        <select v-model="code.currency" class="form-control">
                            <option v-for="cu in currencies" :value="cu.code">{{cu.code}}, {{cu.name}}</option>
                        </select>
                    </div>
                </div>



                <div class="col-md-6">
                    <div class="form-group">
                        <label>Platnost od: (včetně dne) </label>
                        <input type="date"  class="form-control" v-model="code.start_date"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Platnost do:  </label>
                        <input type="date"  class="form-control"  v-model="code.end_date"/>
                        (včetně toho dne - tedy ten den bude kupon ještě platný)
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-12">
                    <div class="form-group">
                        <label>Pro jaký e-shop:</label>
                    </div>

                </div>

                <div v-for="e in eshops" class="col-md-4">
                    <label><input type="checkbox" v-model="es[e.symbol]"/> {{e.invoice_prefix}}  </label>
                </div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Typ slevy:</label>

                        <select class="form-control" v-model="code.kind">
                            <option value="repeat">pro opakované předplatné (každý měsíc se slevou)</option>
                            <option value="one">jednorázový nákup</option>
                        </select>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Sleva pro samostatný produkt:</label>

                        <select-product :product="only_product" @onProductSelect="onOnlyProductSelect"></select-product>

                    </div>

                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Sleva na kategorii produktů:</label>
                        <select class="form-control" v-model="code.only_category">
                            <option></option>
                            <option v-for="c in categories" :value="c.id">{{c.name.cs}}</option>
                        </select>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Vyloučit produkt</label>

                        <select-product :product="exclude_product" @onProductSelect="onExcludeProductSelect"></select-product>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label> Vyloučit kategorii:</label>

                        <select class="form-control" v-model="code.exclude_category">
                            <option></option>
                            <option v-for="c in categories" :value="c.id">{{c.name.cs}}</option>
                        </select>

                    </div>
                </div>

            </div>
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group">
                        <label> Limit počtu využití:</label>
                        <input type="text" class="form-control" v-model="code.usage_limit"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>  Limit využití na jednu e-mailovou adresu:</label>
                        <input type="text" class="form-control" v-model="code.limit_student"/>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label> Nelze kombinovat s dalšími slevami: <input type="checkbox" v-model="code.disable_combine"/></label>
                    </div>

                </div>

                <div class="col-md-12">
                                <button class="btn btn-primary" @click.prevent="handleSaveChanges">
                                    Uložit změny
                                </button>
                </div>
            </div>
            <pre>{{code}}</pre>
        </div>

    </vue-final-modal>
</template>
<script>


import modalKkMixin from "./conversation-club/modal-kk-mixin";
import SelectProduct from "./select-product.vue";
import axios from "axios";

export default {
    name: "discount-code-form",
    mixins:[modalKkMixin],
    components:{SelectProduct},
    props:['code', 'eshops'],
    computed:{
        eshops_extract(){
            let arr = []
            for(let i in this.es){
                if (this.es[i]) arr.push(i)
            }
            return arr.join(',')
        }
    },
    watch:{
      code(){
          let a=[]
          this.es={}
          if (this.code.eshops_array) {
              a = this.code.eshops_array.split(',')

              for(let i in a){
                  this.es[a[i]] = true
              }
          }
          console.log('change ES ', this.code.id, a)

          this.exclude_product = this.code.exclude_product_obj
          this.only_product = this.code.only_product_obj
      },


    },
    data(){
        return {
            reasons_select: {
                test: 'testování',
                ac: 'akce',
                influencer: 'influencer',
                affil: 'affilák',
                partner: 'partner',
                b2b: 'B2B'
            },
            exclude_product:{},
            only_product:{},
            categories:[],
            currencies:[],
            es:{}



        }
    },
    async mounted() {

        let cr = await axios.get('/api/course_categories')
        this.categories = cr.data

        let a = await axios.get('/api/currencies')
        this.currencies = a.data.currencies

    },
    methods:{
        onProductSelect(){

        },
        onOnlyProductSelect(p){
            this.only_product=p
            if (p) this.code.only_product = p.id; else this.code.only_product= null
        },
        onExcludeProductSelect(p){
            this.exclude_product = p
            if (p)
                this.code.exclude_product = p.id || '';
            else {
                this.code.exclude_product = ''
                this.exclude_product={}
            }
        },
        handleSaveChanges(){
            const data = { code: this.code }

            data.code.eshops_array = this.eshops_extract
            this.code.eshops_array = this.eshops_extract


           if (this.code.id>0){
               axios.put(`/discount_codes/${this.code.id}`, data).then(response => {
                   this.hideModal()
                   this.$emit('codesChanged')
               })
           }else{

               axios.post('/discount_codes', data).then(response => {

                   this.hideModal()
                   this.$emit('codesChanged')
               })

           }



        }
    }

}
</script>



<style scoped>

</style>
<script>
import {VueFinalModal} from "vue-final-modal";
import FInputCategory from "../fields/FInputCategory.vue";
export default {
  name: "FGInputCategory",
  props: ['options'] ,
  components: {
    VueFinalModal, FInputCategory
  },
  data(){
    return({
      showM: false,
      new_item:'',
      optskye:28800,
      color_list: [
      "F3F3F3" ,    "FFD1D1",    "CFFCC7",    "B5E3FF",    "FAF2C4",    "E3D6FF",    "FFD5B8",
    "BDF0ED",    "C8C8C8" ,    "FF7373" ,    "8FDE6B" ,    "7DC7FF",    "FFD652" ,    "CCADFF" ,    "FF8B38" ,    "36C2CC" ]



    })
  },
  methods:{
    hideModal(){
      this.showM=false

    },
    addCatItem(){
      this.options.items.push( {
        t: this.new_item.toString(),
        color:"FAF2C4",
        value: this.getMaxVal()+1
      })
      this.new_item=''
    },
    getMaxVal(){
      let max=0
      for (let i in this.options.items){
        if (this.options.items[i].value){
          if (this.options.items[i].value > max){
            max = this.options.items[i].value
          }
        }
      }
      return max
    },

    removeItem(it){

      for(let i=0; i< this.options.items.length; i++){
        if (this.options.items[i] == it){
          this.options.items.splice(i,1);
          break;
        }
      }

    },
    showColorpikr(el){
      const e = this.$refs[  `colop${el.objid}` ]
      e[0].style.display=''
    },

    onPickColor(it, color){
      it.color=color
      const e = this.$refs[  `colop${it.objid}` ]
      e[0].style.display='none'
      this.optskye+=1
    }

  },
  mounted() {
    for(let i=0; i< this.options.items.length; i++) {
      this.options.items[i].objid = i

    }
  }
}
</script>

<template>
  <div>


            <FInputCategory :options="options" :item="{}"></FInputCategory>

    <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f mcw1">

      <div class="modal-header">
        <span class="modal__title fg-modal-title">Vlastnosti prvku</span>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal__content" >
        <div class="form-group">
          <label>Label</label>
          <input type="text" class="form-control" v-model="options.label"/>
        </div>

        <div class="form-group" :key="optskye">
          <div v-for="it in options.items" class="category-item-ed d-flex"  >
            <input type="text" v-model="it.t" class="border-0 form-control"/>

            <div class="ml-auto">
              <div class="color-sample" :style="`background-color: #${it.color}`" @click.prevent="showColorpikr(it)" >
                <ul class="colorpicker1" style="display: none" :ref="`colop${it.objid}`">

                  <li v-for="c in color_list" :style="`background: #${c}`"
                      @click.prevent.stop="onPickColor(it, c)"></li>

                </ul>
              </div>
            </div>
            <div class="ml-2">
              <a @click.prevent="removeItem(it)">smazat</a>
            </div>
          </div>




          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Kategorie" v-model="new_item">
            <div class="input-group-append">
              <button class="btn btn-success" type="button" @click.prevent="addCatItem">Add</button>
            </div>
          </div>


        </div>



      </div>
    </vue-final-modal>
  </div>
</template>

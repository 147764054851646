var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-body index p-0"},[_c('DialogSendPushnotifikace',{ref:"dialogPosilani",attrs:{"token":_vm.current_token}}),_vm._v(" "),_c('AppTable',{attrs:{"app":_vm.app,"fields":_vm.fields,"enable_search":true,"filters":[{type: 'search'},
                        { type:'select',
                          label: 'system',
                          codename: 'kind',
                          options:['All','apple', 'android']
                        }],"actions":[
            {
            label:'poslat',
            header: 'akce',
              method: _vm.extravurst
            }
            ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="row mb-2">
    <div class="col-md-4">
      <label for="order_status" class="label">Stav<abbr title="povinný">*</abbr></label>

        <select name="order[status]" id="order_status" v-model="status" class="form-control">
          <option :value="v[1]" v-for="(v) in collection">{{v[0]}}</option>
        </select>

      <input type="hidden" name="order[payment_attributes][status]"  id="order_payment_attributes_status" v-model="status" />


    </div>

    <div class="col-md-8" v-if="order.id_oj_eshop>0">
      <label for="order_status" class="label">Stav v onlinejazyky</label>
      <div class="d-flex">
        <div class="w-50">
          <select  v-model="status_oj" class="form-control">
            <option value="0"> Nezpracováno </option>
            <option value="1">Čekáme na platbu</option>
            <option value="2">Zaplaceno a dosud neaktivováno</option>
            <option value="3">Vyřízeno, aktivováno</option>
            <option value="4">Stornováno</option>
            <option value="5">Vráceny peníze</option>
          </select>

        </div>
        <button @click.prevent="oj_statusUpdate"  class="btn btn-sm ml-2 btn-outline-success">Zapsat stav do OJ-eshopu</button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  props:['collection', 'order'],
  name: "order-status-update",
  data(){
    return {
      status: this.order.status,
      status_oj: null,
    }
  },
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true

    this.initStatus()

  },
  watch:{
    status: 'initStatus'
  },
  methods:{
    initStatus() {

      const trans = {
        'processing': 0,
        'created': 0, //'Vytvořena', :created, 1, 1),
        'pending': 1, //'Čeká na platbu', :pending, 2, 2),
        'failed': 1, //'Platba selhala', :failed, 3, 3),
        'completed': 2, //'Zaplacena', :completed, 4, 4),
        'cancelled': 4, //'Zrušena', :cancelled, 5, 5),
        'refunded': 5, //'Vrácená', :refunded, 6, 6),
        'expired': 4, //'Vypršela', :expired, 7, nil),
        'active': 2, //'Aktivní', :active, 8, nil),
        'on-hold': 4, //'Pozastavena', :on_hold, 9, nil),
        'pending-cancel': 4 //'Probíhá zrušení', :pending_cancel, 10, nil)
      }

      this.status_oj = trans[this.status]

    },


    oj_statusUpdate(){
      const data={
        status_oj: this.status_oj,
        status: this.status

      }
      const url = `/orders/${this.order.id}/update-status`
      axios.put(url, data).then(response => {
        console.log("R", response.data.items)
        var ojr = response.data.oj_api_resp
        if (ojr.result == 'ok'){
          this.status=response.data.order.status
          this.$toasted.show("Stav byl uložen", {type: 'success'}); //Type of the Toast ['success', 'info', 'error']
        }else{
          this.$toasted.show(`Chyba spojení s OJ: ${ojr.errorDetails}`, {type: 'error'}); //Type of the Toast ['success', 'info', 'error']
        }


      }).catch(err => {
          this.$toasted.show(err.response.data.error, {type: 'error'}); //Type of the Toast ['success', 'info', 'error']
      })

    }

  }


}
</script>

<style scoped>

</style>
<template>

  <div>

<div class="">
  <editor v-if="fi" :filter="fi" :eshops="eshops" @cancel="fi=null"></editor>
<div v-else>
  <div class="float-right">
    <button class="btn btn-dark" @click.prevent="addNewFilter">Vytvořit nový filtr</button>
  </div>
  <table class="table table-striped w-auto" >
    <thead>
      <tr>
        <th>ID</th>
        <th>Název scénáře</th>
        <th>Aktivni</th>
        <th colspan="2">  </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="nf in filters" :key="nf._id">
        <td>{{nf.id}}</td>
        <td><a href="#" @click.prevent="fi = nf">{{nf.name}}</a></td>
        <td>{{nf.active ? "ano": "ne"}}</td>
        <td><a href="#" @click.prevent="selectStudents(nf.id)">vyfiltrovat</a></td>
        <td><button @click="removeItem(nf)" class="btn btn-outline-danger btn-smallish ml-5">smazat</button></td>
      </tr>
    </tbody>
  </table>

</div>

</div>
    <b>Seznam</b>
    <div> {{select_query}}</div>
    <div v-if="total>0">Nalezeno: {{ total }}</div>
    <div v-if="loading" class="text-center mt-auto">
      <img src="/img/1495.gif" />
    </div>

    <table v-else class="table table-striped">
      <thead>
        <tr>
          <td>jmeno</td>
          <td>email</td>
          <td>Komunikacni jazyk</td>
          <td>brand</td>
          <td>Odkud je</td>
          <td>city</td>
          <td>country</td>
          <td>dnesni doba studia</td>
        </tr>
      </thead>
      <tbody>
      <tr v-for="s in students">
        <td>{{s.name}} {{s.surname}}</td>
        <td>{{s.email}}</td>
        <td>{{s.language_name}}</td>
        <td>{{s.brand}}</td>
        <td>{{source_kind_name(s.source_kind)}}</td>
        <td>{{s.city}}</td>
        <td>{{s.country}}</td>
        <td>{{s.today_time}}</td>
      </tr>
      </tbody>
    </table>
    <pre v-html="errors" v-if="errors" class="errors"></pre>


  </div>
</template>

<script>
import axios from "axios";
import Editor from "./editor.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: "filters",
  components: {Editor},
  props:['eshops'],
  data(){
    return({
      filters: [],
      loading: false,
      fi: null,
      select_query:'',
      students:[],
      errors:null,
      total:0
    })
  },
  mounted() {
    this.loadFilters()
  },
  methods:{

    source_kind_name(i){

      const arr = ['company', 'school', 'language_school', 'eshop']
      if (i==null || i==undefined) return "?"
      return arr[i]
    },



    selectStudents(nf_id){
      this.errors=null
      this.total=null
      this.students = []
      this.loading=true
      this.select_query = ''
      axios.get(`/notify_filter/students/${nf_id}`).then(response => {
        this.loading = false
        this.students = response.data.results
        this.select_query = response.data.query
        this.total = response.data.total
      }).catch(err => {
        this.loading = false
        //this.$toast.error('Chyba')

        this.$toasted.show(`Chyby`, {type: 'error'});

        this.errors = err.response.data

      })

    },

    addNewFilter(){
      const new_fi = {
        _id: uuidv4(),
        run_hour:12,
        active: true,
        segments:[
          { _id: uuidv4(),
            segment_operator: null,
            conditions:[
              {
                _id: uuidv4(),
                operator:'',
                course_id: null,
                student_typ_course_id: null,
                "student_typ_course": "any",
                date_compare: 'reg',
                "language_category": ["All"]
              }]
          }
        ],
      }
      this.filters.push( new_fi )
      this.fi = new_fi
    },

    removeItem(nf){

      if (!confirm(`Opravdu smazat  ${nf.name} ?`)) return

      if (nf.id>0) {


        axios.delete(`/notify_filter/${nf.id}`).then(response => {
          for (var i in this.filters) {
            if (nf == this.filters[i]) {
              this.filters.splice(i, 1)
            }
          }
        })
      }else{

        for (var i in this.filters) {
          if (nf == this.filters[i]) {
            this.filters.splice(i, 1)
          }
        }

      }

    },


    loadFilters(){
      axios.get(`/notify_filter/all`).then(response => {

        console.log('resp', response.data)
        this.filters = response.data.notify_filters
      }).catch(err => {

       // this.$toast.error('Chyba')
        this.errors = err.response.data

      })
    }
  }
}
</script>

<style scoped>
pre.errors{
  border:2px solid red; padding: 20px; margin:15px
}
</style>
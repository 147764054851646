<template>
<div class="nf-action">
  <div class="row">
    <div class="col-md-2">
      <label>Druh akce</label>
      <select v-model="action.kind" class="form-control">
        <option value="pushn"> Push notifikace </option>
        <option value="appn" disabled> In app notifikace </option>
        <option value="email" disabled> Email </option>
        <option value="iemail"> Interní Email </option>
        <option value="badge" disabled> Přidělit badge </option>
        <option value="www" disabled> Zobrazit na webu / FB </option>
      </select>

      <div class="mt-5">


        <button class="remove-segment" @click="removeAction"> smazat</button>
      </div>


    </div>
    <div class="col-md-10">

      <div v-if="action.kind=='iemail'">
        <label>Poslat na adresu</label>
        <input type="text" v-model="action.email_to" class="form-control"/>

      </div>

      <label>Obsah</label>
      <textarea v-model="action.content" class="form-control" rows="8"></textarea>

    </div>
  </div>
</div>
</template>

<script>

export default {
  props:['action'],

  name: "notify-action",
  data(){
    return({
      a_www: false,
      a_badge: false,
      a_email: false,
      a_iemail: false,
      a_appn: false,
      a_push: false,
      content:''
    })
  },
  methods:{
    removeAction(){
      this.$emit('removeAction', this.action)
    }
  }
}
</script>

<style scoped lang="scss">
.nf-action{
  border: 1px dotted darkseagreen;
  background: #ffffbd;
  padding:10px;
}





.remove-segment{
  display: inline-block;
  width:auto;
  padding-left:  28px;
  height:28px;
  border:1px solid #eee;
  border-radius: 15px;
  background: #fff url('/img/delete-forever.png') 2px 2px no-repeat;
  background-size: 20px 20px;

  top:-12px;
  right:-12px;
  &:hover{
    background-color: indianred;
  }
}
</style>
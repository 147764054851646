import moment from "moment/moment";

const ojMixin ={

  data(){
    return{
      page: 1,
      total:2000,
      per: 30
    }
  },

  filters: {

    date(value) {
      value = moment(value).format('DD. MM. YYYY')
      return value
    },

    dateTime(value) {
      value = moment(value).format('DD. MM. YYYY, H:mm')
      return value
    },

    dateTime2(value) {
      value = moment(value).format('DD.MM.YYYY H:mm')
      return value
    },


    decimal(v){
      const f = parseFloat(v)
      return f.toLocaleString('cs-CZ', { style: 'decimal' });
    }

  }


}


export default ojMixin
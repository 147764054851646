<script>
import axios from "axios";
import lessonFormMixin from "./lesson-form-mixin";
import LessonForm from "./lesson-form.vue";
export default {
//  admin_lessons
  created() {


    const lector = parseInt( window.localStorage.getItem('conversation_club_lessons_lector_id') )
    this.filter_status = window.localStorage.getItem('conversation_club_lessons_status')




    console.log("lektor filtr", lector)



    axios.get('/conversation-clubs/lectors.json',{ params:{

        per: 1000
      }}).then(r2 => {
        this.lectors = r2.data.items
        if (lector>0){
          for(let i in this.lectors){
            if (this.lectors[i].id == lector) this.selected_lector = this.lectors[i]
          }
        }

        this.loadLessons()
        this.onLectorSearch('')
      })
  },

  data(){
      return {
        selectall: false,

        d1: window.localStorage.getItem('conversation_club_lessons_d1' ),
        d2: window.localStorage.getItem('conversation_club_lessons_d2'),
        lectors:[],
        selected_lector:{},
        filtered_lectors:[],
        filter_status:'',

        lesson_students:[],
        current_l: {},
        lessons: [],
        is_admin:false,
        current_week_start: moment().startOf('week'),
        sum_costs:'',
        hh:0,

      }
  },
  mixins:[lessonFormMixin],
  components:{ LessonForm},
  watch:{
    d1: 'loadLessons',
    d2: 'loadLessons',
    selected_lector: 'loadLessons',
    filter_status: 'loadLessons',
    selectall(){

      for(let i in this.lessons){
        this.lessons[i].selected = this.selectall
      }
      this.selected_ids()
    }

  },

  methods:{
    onSelectedCbChanged(){

      this.selected_ids()

    },
    selected_ids(){
      let arr = []
      for(let i in this.lessons){
        if (this.lessons[i].selected){
          arr.push(this.lessons[i].id)
        }
      }
      this.hh = arr.length
      return arr
    },


    df(s){
      return moment(s).format('D.M. YYYY')
    },
    loadLessons(a){

      window.localStorage.setItem('conversation_club_lessons_d1', this.d1)
      window.localStorage.setItem('conversation_club_lessons_d2', this.d2)
      let lid=null
      if (this.selected_lector && this.selected_lector.id){
        lid = this.selected_lector.id
      }
      window.localStorage.setItem('conversation_club_lessons_lector_id',lid)
      window.localStorage.setItem('conversation_club_lessons_status', this.filter_status)

      axios.get('/conversation_club/lessons/admin_lessons', {
        params:{
          d1: this.d1,
          d2: this.d2,
          lector_id:  this.selected_lector ? this.selected_lector.id : null,
          status: this.filter_status
        }
      }).then(response => {

        this.user_id = response.data.current_user_id
        this.lessons = response.data.lessons
        this.is_admin = response.data.is_admin
        this.sum_costs = response.data.sum_costs
        this.all_ids = response.data.all_ids
        this.selected_ids()


      })
    },

    setLessonStatus(lesson, new_status){

      const data={
        id: lesson.id,
        status: new_status
      }


      axios.put(`/conversation-clubs/update-lesson-status`, data).then( response => {
        lesson.status = response.data.lesson.status
      }).catch(err => {
        this.$toasted.show(`Chyba: ${err.response.data.error}`, {type: 'error'});

      })

    },

    badgeClassByLstatus(l){
      if (l.status == 'approved') return 'badge-success'
      if (l.status == 'denied') return 'badge-danger'
      return "badge-info"
    },

    batchApprove(){
      const data={
        ids: this.selected_ids(),
        status: 'approved'
      }

      axios.put('/conversation_club/lessons/batch-update', data).then( response => {

        this.loadLessons()

      }).catch(err => {
        this.$toasted.show(`Chyba: ${err.response.data.error}`, {type: 'error'});

      })
    },

    handleLessonClicked(l){
      this.current_l=l

      if (l.id > 0) {
        axios.get(`/conversation-clubs/lesson/${l.id}`).then(response => {
          this.lesson_students = response.data.students
        })
      }
      this.$refs.modaaLesson.showModal()




    },

    onLectorSearch(search){
      const s = search.toLowerCase()
      if (search==null || search=="") this.filtered_lectors = this.lectors

      this.filtered_lectors=[]

      for(let i in this.lectors){

        const l = this.lectors[i]
        const str = `${l.first_name.toLowerCase()} ${l.last_name.toLowerCase()}`
        if (str.indexOf(s)>=0)
          this.filtered_lectors.push(l)
      }

    },
    handleRemoveLesson(lesson){
      if (confirm(`Opravdu smazat  L${lesson.level} ${lesson.topic_name} ?`)){

        axios.delete(`/conversation_club/remove-lesson/${lesson.id}`).then( response =>{
          this.loadLessons()
        }).catch(err => {
          this.$toasted.show(`Chyba: ${err.response.data.errors.join('<br>')}`, {type: 'error'});
        })

      }
    },



  }
}
</script>

<template>
<div class="card card-body">
<lesson-form ref='modaaLesson'
                 :lesson="current_l"
                 @close="lesson_students=[]"
                 @calendarUpdated="loadLessons"
                 :lesson_students="lesson_students"
                 :student_id="null"
                 :admin="is_admin"
                 :wdays="wdays()"></lesson-form>

  <div class="filtr d-flex">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">datum od </span>
      </div>
      <input type="date" class="form-control" v-model="d1"/>
    </div>

    <div class="input-group ml-2">
      <div class="input-group-prepend">
        <span class="input-group-text">do </span>
      </div>
      <input type="date" class="form-control " v-model="d2"/>
    </div>


    <div class="input-group ml-2" >
      <v-select v-model="selected_lector" @search="onLectorSearch" :filterable="false" :options="filtered_lectors" :disabled="!is_admin"  style="width: 300px">

        <template #option="option">
          <div class="d-center row">
            <div class="col-1">
              <img :src="option.profile_image_url" v-if="option.profile_image_url" class="icoproduct">
            </div>
            <div class="col-6">{{ option.first_name }} {{ option.last_name }}</div>
          </div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">
            <img :src="option.profile_image_url" v-if="option.profile_image_url" class="icoproduct"> {{ option.first_name }} {{ option.last_name }}
          </div>
        </template>
      </v-select>
    </div>

    <div class="input-group ml-2" >
      <select class="form-control" v-model="filter_status">
        <option value="">Všechny statusy</option>
        <option value="inserted">inserted</option>
        <option value="approved">approved</option>
        <option value="denied">denied</option>

      </select>
    </div>


  </div>


  <div class="d-flex mb-2" v-if="hh>0">
    <div class="col" >
      HROMADNÉ AKCE (vybráno {{hh}}):  <button class=" btn  btn-success " @click.prevent="batchApprove">schválení</button>

    </div>
  </div>


<table class="table table-bordered">
  <thead>
    <tr>
      <th><input type="checkbox" v-model="selectall" /></th>
      <th>WHEN </th>
      <th>LECTURER</th>
      <th>KK DESCRIPTION</th>
      <th>COSTS</th>
      <th>STATUS</th>
      <th>AKCE</th>
    </tr>
  </thead>

  <tbody>
    <tr v-for="l in lessons">
      <td><input type="checkbox" v-model="l.selected" @change="onSelectedCbChanged"/></td>
      <td>{{df(l.day)}}  &nbsp; {{l.hour}}:00</td>
      <td><img :src="l.profile_image_url" v-if="l.profile_image_url" class="miniico"/> {{l.lector_full_name}}</td>
      <td>L{{l.level}} {{l.topic_name}}</td>
      <td>{{l.costs}}</td>
      <td>
        <span :class="`badge ${badgeClassByLstatus(l)}`">{{l.status}}</span>

      </td>
      <td>
        <a v-if="is_admin" href="#" class="tup" @click.prevent="setLessonStatus(l,'approved')"><span class="material-symbols-outlined">      thumb_up          </span></a>

        <a  v-if="is_admin" class="tdown" href="#"  @click.prevent="setLessonStatus(l,'denied')"><span class="material-symbols-outlined">thumb_down</span></a>

        <a href="#"  @click.prevent="handleLessonClicked(l)"><span class="material-symbols-outlined">edit</span></a>


        <a href="#"  v-if="is_admin"  @click.prevent="handleRemoveLesson(l)" class="ml-2"><span class="material-symbols-outlined mazani">delete</span></a>

      </td>
    </tr>
  </tbody>
</table>

  <div class="row sumarow">
    <div class="col-md-9 sumarowcol">
        SUMA z vyfiltrovaných COSTS
    </div>
    <div class="col-md-2 sumarowcol ">
      {{sum_costs}}
    </div>
  </div>


</div>
</template>

<style scoped>
  .tup{
    color:lawngreen;
  }
  .tdown{
    color:red
  }

.icoproduct{
  max-height: 20px;
}

.miniico{
  height: 30px;
  border-radius: 14px; margin-right: 30px;
}

.filtr{
  margin-bottom: 15px;
}
.sumarow{
  margin:1px
}
.sumarowcol{

    background: #eee;
    font-size:120%

}

.mazani{
  color:#dd0000;
}
</style>
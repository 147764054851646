<template>
  <div class="mb-2">
    <label class="label">Student</label>
    <input type="hidden" :name="name" :value="selected_student.id" />
    <v-select label="name" :filterable="false" :options="options" @search="onSearch" v-model="selected_student">
      <template #no-options>
        začni psát pro vyhledání studenta
      </template>
      <template #option="option">
        <div class="d-center">
          <b>{{ option.name }} {{ option.surname }}</b>  {{ option.email }}
        </div>
      </template>
      <template #selected-option="option">
        <div class="selected d-center">
            <b>{{ option.name }} {{ option.surname }}</b>  {{ option.email }}
        </div>
      </template>
    </v-select>

  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "select-student",
  props:['name', 'student'],
  data(){
    return({
      options:[],
      selected_student: this.student
    })
  },
  mounted() {

  },
  methods:{


    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      fetch(
          `/search/students?term=${(search)}`
      ).then(res => {
        res.json().then(json => (vm.options = json));
        loading(false);
      });
    }, 350)


  }

}
</script>

<style scoped>
  .v-select{
    max-width: 95%;
  }
</style>
<template>
  <div>

      <div class="w-25 border-info">

          <label>Heslo</label>
          <input type="password"  class="form-control" v-model="p1"/>


          <label>Opakovat heslo</label>
          <input type="password"  class="form-control" v-model="p2"/>


          <button class="btn btn-primary" @click.prevent="handleSetPassword" :disabled="!valid">Nastavit heslo</button>


      </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  name:'set-password',
  props: ["student_id"],

  data() {
    return {
      valid: false,
      p1:'',
      p2:''
    }
  },
  watch:{
    p1: 'validator',
    p2: 'validator'
  }
  ,
  methods:{

    validator(){
      let v = true
      if (this.p1=='' || this.p1=='') v=false
      if (this.p1!=this.p2) v=false


      this.valid = v
    },

    handleSetPassword(){
      axios.post(`/shopping_cart/set-password`, {
        pass: this.p1,
        student_id: this.student_id
      }).then((response) => {

      });
    }
  }



}



</script>


<style scoped>

</style>
import { Controller } from "stimulus"
let crypto = require('crypto')

export default class extends Controller {
    static targets = ["template", "container"]

    connect() {
        console.log("growing form connected")
    }

    add() {
        const template = this.templateTarget
        console.log(template)
        const clone = template.content.cloneNode(true)
        const needChange = clone.querySelectorAll('input, select')
        console.log(needChange)

        const randomHex = crypto.randomBytes(6).toString('hex')
        const randomInt = parseInt(randomHex, 16)
        console.log(`hex: ${randomHex}, int: ${randomInt}`)

        needChange.forEach(elem=> {
            elem.name = elem.name.replace("0", randomInt)
        })

        this.containerTarget.appendChild(clone)
    }
}

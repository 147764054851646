<script setup>
import axios from "axios";
import OjPagination from "../OjPagination.vue";
import FilterDateRange from './filters/date-range.vue'
import moment from "moment";
export default {
  name: 'AppTable',
  props: ['app','fields', 'datatransformer', 'pers', 'default_per', 'enable_search', 'filters' , 'actions'],
  components:{ OjPagination, FilterDateRange  },

  data(){
    return {
      items:[],
      search_string:'',
      total:0,
      page:1,
      per: this.default_per,
      ppers:[10,20,30,50,80],
      filter_params:{},
      kii: 4000
    }
  },
  mounted() {
    if (this.pers) this.ppers=this.pers
    this.loadItems()
  },
  watch: {
    per(){
      this.page=1
      this.loadItems()
      this.kii++
    },

    search_string(){
      this.search(this)
    }
  },

  computed:{
    filtered_cols_count(){
      let c=0
      for(let i in this.fields){
        if (this.fields[i].filter) c++
      }
      return c
    }
  },

  methods:{
    handleClearFilter(){
      this.filter_params={}
      this.search_string = ''
      this.page=1
      this.loadItems()
    },
    search: _.debounce((vm) => {
      vm.page=1
      vm.loadItems()
    }, 550),
    pageChanged(p) {
      this.page = p
      this.loadItems()
    },
    loadItems(){
      let params={
        per: this.per,
        page: this.page,
        search: this.search_string,
        filter: this.filter_params
      }
      axios.get(this.app.data_url ? this.app.data_url  : `/formgenerator/app/${this.app.codename}/index`, {params: params}).then(response => {
        if (typeof this.datatransformer == 'function')
          this.items = this.datatransformer(response.data.items)
        else
          this.items = response.data.items
        this.total = response.data.total
      })
    },

    itemClick(item){
      this.$emit('itemClick', item)
    },

    date(value) {
      value = moment(value).format('DD.MM.YYYY')
      return value
    },

    dateTime(value) {
      value = moment(value).format('DD.MM.YYYY H:mm')
      return value
    },

    filterItemChange(field){
      this.page=1
      this.loadItems()
    },

    preformat(item, c ){
      const v = item[c.codename]
      if (c.display_filter=='dateTime') return this.dateTime(v)

      return v
    },
    deletable(){

      return this.app.actions.indexOf('delete')>=0
    },
    handleDelete(item){
      if (!confirm("Opravdu samzat ?")) return
      if (this.app.destroy_url){
        const u = this.app.destroy_url.replaceAll(":id", item.id)
        axios.delete(u).then(resp =>{
          this.loadItems()
        }).catch(err => {
          this.$toasted.show(`Chyba: ${err.response.data.errors.join('<br>')}`, {type: 'error'});
        })


      }
    },

    dateRangeChange(f, d_od, d_do){
      this.filter_params[`${f.codename}_date_to`] = d_do
      this.filter_params[`${f.codename}_date_from`] = d_od
      this.search(this)
    },

    normalizedOptions(src){
      const dest = []

      if (src instanceof Array) {
        for (let i in src) {
          if (typeof src[i] == 'string') {
            dest.push({value: src[i], text: src[i]})
          }
        }
      }else {
        if (typeof src == 'object') {
          for (let i in src) {
            dest.push({value: src[i], text: i})
          }
        }
      }``
      return dest
    }

  }
}
</script>

<template>
<div>
  <div class="filtr row" v-if="filters && filters.length>0">
    <div class="col-md-4" v-for="f in filters">
      <FilterDateRange  :filter="f" v-if="f.type=='date-range'" @change="dateRangeChange"/>

       <div class="form-group" v-if="f.type=='select'">
         <label>{{f.label}}</label>
         <select class="form-control" v-model="filter_params[f.codename]" @change="filterItemChange(f)">
           <option :value="oo.value" v-for="oo in normalizedOptions(f.options)" >{{oo.text}}</option>
         </select>
       </div>
      <div class="form-group" v-if="f.type=='search'">
        <label>&nbsp;</label>
        <div  class="input-group ml-2">
          <div  class="input-group-prepend">
            <div  class="input-group-text">
              <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" class="svg-inline--fa fa-search fa-w-16"><path  fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
            </div>
          </div>
          <input  placeholder="Hledat" type="text" class="form-control" v-model="search_string"/>
        </div>
      </div>

    </div>

    <div class="col-md-4">

    </div>
  </div>

  <div class="row" v-if="filtered_cols_count">
    <div class="col-md-2 text-right ml-auto">
      <button class="btn2 btn-outline-dark" @click.prevent="handleClearFilter()">Vyčistit filtr</button>
    </div>
  </div>

  <div class="ml-3 d-flex" v-if="total>10" :key="`duuu${kii}`">
    <div class="mt-3 mr-2 ml-auto">stránkovani po</div>
      <select v-model="per" class="form-control w-auto mt-2 mr-2">
        <option v-for="p in ppers" :value="p">{{p}}</option>
      </select>


    <OjPagination
        :totalItems="total"
        :currentPage="page"
        :perPage="per"
        @pageChanged="pageChanged" class="mt-2"/>

    <div  class="mt-3 ml-2 mr-auto">
      z celkových {{total}}
    </div>
  </div>

  <div v-if="items.length==0" class="nic text-center">
    nic nebylo nalezeno
  </div>
  <table class="table table-striped" v-else>
    <thead>
      <tr>
        <th></th>
        <th v-for="c in fields">{{c.label}}</th>
        <th v-if="deletable()"></th>

        <td v-for="act in actions">{{act.header}}</td>

      </tr>
    </thead>
    <tbody>
    <tr v-for="item in items" @click.prevent="itemClick(item)">
      <td>{{item.id}}</td>
      <td v-for="c in fields" :class="`cell-${c.codename}`">
        {{ preformat(item, c ) }}
      </td>

      <td v-if="deletable()">
        <a href="#" @click.prevent="handleDelete(item)" title="smazat"><span class="material-icons-outlined mazani">delete</span></a>
      </td>

      <td v-for="act in actions">
        <button @click.prevent="act.method(item)">{{act.label}}</button>
      </td>
    </tr>
    </tbody>
  </table>


  <div class="ml-3 d-flex" v-if="total>10" :key="`druhejpaginator${kii}`">
    <div class="mt-3 mr-2 ml-auto">stránkovani po</div>
    <select v-model="per" class="form-control w-auto mt-2 mr-2">
      <option v-for="p in ppers" :value="p">{{p}}</option>
    </select>


    <OjPagination
        :totalItems="total"
        :currentPage="page"
        :perPage="per"
        @pageChanged="pageChanged" class="mt-2"/>

    <div  class="mt-3 ml-2 mr-auto">
      z celkových {{total}}
    </div>
  </div>

</div>
</template>

<style scoped>
.mazani{
  color: #cc0000;
}
</style>
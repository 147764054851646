<template>
<div class="card card-body index p-0">
  <div class="top-button-group">
    <a href="/conversation-clubs" class="btn btn-light">Zobrazit rozvrh</a>
  </div>

  <div class="d-flex">
      <div class="ml-auto p-2">
       <button class="btn btn-primary" @click.prevent="addStudent"><span class="material-icons mia">add_circle</span> Přidat studenta</button>
        <button class="btn btn-primary" @click.prevent="importStudents"><span class="material-icons mia">group</span> Přidat studenty z XLSX</button>
        <button class="btn btn-outline-info" @click.prevent="reporter = !reporter">Vytvorit report</button>
      </div>
  </div>



    <div class="filters d-flex ml-2">
      <div class=" form-group">
        <label>Firma</label>
        <select v-model="organization_filter" class="form-control">
          <option value=""> - </option>
          <option v-for="o in organizations" :value="o.id">{{o.name}}</option>
        </select>
      </div>




        <div class="form-group ml-1">
          <label>Aktiv/neaktiv</label>
          <select class="form-control" v-model="activ_filter">
            <option value=""> Aktivní i neaktivní</option>
            <option value="active">Aktivní</option>
            <option value="expired">Neaktivní</option>
          </select>
        </div>

        <div class="form-group ml-1">
          <label>přihlášení v týdnech</label>
          <select class="form-control" v-model="week_noreg_filter">
            <option value="">všichni</option>
            <option v-for="w in week_filters" :value="w.w">kdo nemá rezervaci v tydnu {{w.label}}</option>
          </select>
        </div>

       <div class="form-group ml-1"><label>&nbsp;</label>
            <div class="input-group ml-2">
                <div class="input-group-prepend">
                    <div class="input-group-text"><svg class="svg-inline--fa fa-search fa-w-16"
                                                       aria-hidden="true" focusable="false" data-prefix="fas"
                                                       data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg"
                                                       viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg><!-- <i class="fas fa-search"></i> --></div>
                </div>
                <input placeholder="Hledat" class="form-control" type="search" name="search" v-model="search_student"/>
            </div>

        </div>

      <div class="ml-2">
        <img src="/img/1495.gif" v-if="loading"/>
      </div>

      <div class="ml-2 form-group">
        <label>&nbsp;</label>

      </div>



    </div>

    <div class="filters reporter d-flex ml-2 mb-2" v-if="reporter">
      <div class="input-group w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">datum od </span>
        </div>
        <input type="date" class="form-control" v-model="d1"/>
      </div>

      <div class="input-group ml-2 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">do </span>
        </div>
        <input type="date" class="form-control " v-model="d2"/>
      </div>
      <div>
        <button class="btn btn-outline-success btn2" @click.prevent="studentReport">Vytvorit xlsx</button>
      </div>









    </div>


<p v-if="nic" class="text-center">Hledanému výrazu neodpovídá žádný záznam</p>

  <OjPagination
      class="m-auto"
      :totalItems="total"
      :currentPage="page"
      :perPage="per"
      @pageChanged="pageChanged"  :key="`apppp${page}`" />

<table class="table" v-if="students.length>0">
    <thead>
  <tr>
    <th>jméno</th>
    <th>příjmení</th>
    <th>e-mail</th>
    <th>Ahtivni</th>
    <th>akce</th>
  </tr>
    </thead>
    <tbody>
      <tr v-for="s in students">
        <td>{{s.name}}</td>
        <td>{{s.surname}}</td>

          <td><a href="#" @click.prevent="handleStudentKKSubs(s)">{{s.email}}</a></td>

        <td><span v-if="s.cc_active" class="badge badge-success">A</span><span v-else class="badge badge-danger">N</span></td>
        <td>
            <a href="#" @click.prevent="handleStudentDialog(s)"><span class="material-icons-outlined">mode_edit_outline</span></a>
            <a href="#" @click.prevent="handleLessons(s)"><span class="material-icons-outlined">date_range</span></a>
        </td>
      </tr>
    </tbody>
</table>

  <OjPagination
      class="m-auto"
      :totalItems="total"
      :currentPage="page"
      :perPage="per"
      @pageChanged="pageChanged" :key="`pppp${page}`" />

  <p class="text-center">zobrazeno {{students.length}} ze {{total}} vyfiltrovaných</p>


    <modal-kk-student :student="current_student" ref="studentDetail" :admin="true"></modal-kk-student>
    <modal-sudent-lessons ref="studentLessons"></modal-sudent-lessons>
    <modal-student-kk-subs ref="studentKKSubs"></modal-student-kk-subs>
    <modal-student-import ref="studentImport" @onImported="handleStudentsImported"></modal-student-import>
</div>
</template>

<script>
import axios from "axios";
import modalKkStudent from "./modal-kk-student.vue";
import ModalSudentLessons from "./modalSudentLessons.vue";
import ModalStudentKkSubs from "./modal-student-kk-subs.vue";
import moment from "moment/moment";
import ModalStudentImport from "./modal-student-import.vue";
import OjPagination from "../OjPagination.vue";

const XLSX = require("xlsx");

export default {
  name: "cc-students",
  components:{OjPagination, ModalStudentImport, ModalStudentKkSubs, ModalSudentLessons, modalKkStudent ,

  },
  data(){
    return({
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheet: {
        name: "SheetOne",
        data: [{a:1, b:1}, {a:'213',b:'23423,34'}]},

      organizations:[],
      reporter:false,
      d1:'',
      d2:'',
      nic: false,
      total:0,
      page:1,
      per:20,
      activ_filter:'',
      organization_filter:'',
      week_filters:[],
      current_student:{},
      students:[],
      search_student:'',
      week_noreg_filter:'',

      loading: false
    })
  },

  created() {
    this.loadStudents()
    this.loadOrganizationsHavingKKStudents()



             //this.current_week_start.format('YYYY-MM-DD')
    let days=[]
    var d = moment().add(-1,'week').startOf('week');


    for(let i=0; i<3; i++){
      let dk = d.clone().endOf('week')
      days.push(
          {
            w: d.format('YYYY-MM-DD'),
            label: `${d.format('DD. MMMM')} - ${dk.format('DD. MMMM')}`
          })
      d.add(1,'week')
    }
    this.week_filters=days


  },
  watch:{
    search_student(){
      this.page=1
      this.search(this.search_student, this);
    },
    week_noreg_filter(){
      this.page=1
      this.loadStudents()
    },
    activ_filter() {
      this.page = 1
      this.loadStudents()
    },
    organization_filter(){
      this.page = 1
      this.loadStudents()
    }

  },
  methods:{

    pageChanged(p){
      this.page=p
      this.loadStudents()
    },

    searchText(){

     // this.search(this.search_text, this);

    },

    search: _.debounce(( search, vm) => {
      console.log('search', search)
      vm.loadStudents()

    }, 550),

    loadOrganizationsHavingKKStudents(){
      axios.get('/conversation_club/students/organizations_with_kk_students.json').then(response => {
        this.organizations = response.data.organizations
      })
    },

    studentReport(){
      if (this.d1=='' || this.d2=='') return

      axios.get('/conversation_club/students/report.json',{
        params:{
          active: this.activ_filter,
          organization: this.organization_filter,
          d1: this.d1,
          d2: this.d2
        }
      }).then(response => {
        //  const items = response.data.items
        this.sheet.data = response.data.xlsdata
        setTimeout(()=>{
          //this.$refs.bubu.click()
        }, 200)

        this.Presidents(response.data.xlsdata)

      })
    },










    Presidents(rows)  {

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  const cols = ['Email',	'Jméno',	'Příjmení'	,'Firma',	'datum poslední rezervace',	'počet rezervací v období reportu',	'report za období']
  XLSX.utils.sheet_add_aoa(worksheet, [cols], { origin: "A1" });
  worksheet["!cols"] = [
      { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 80 },

    { wch: 30 },
    { wch: 40 },
    { wch: 40 } ];

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `report-KK-${this.d1}_${this.d2}.xlsx`, { compression: true });
},



    loadStudents(){
      this.loading=true
      this.students = []
      this.nic=false
      axios.get('/conversation_club/students/index.json',{
        params:{
          q: this.search_student,
          week_filter: this.week_noreg_filter,
          active: this.activ_filter,
          organization: this.organization_filter,
          per: this.per,
          page: this.page
        }
      }).then(response =>{
        this.loading=false
        this.students = response.data.items
        this.total = response.data.total

        if (response.data.items.length==0){
          this.nic = true
        }
      }).catch(e =>{
        this.$toasted.show(`U studentů se vyskytla chyba.`, {type: 'error'});
        this.loading=false
      })
    },

    handleStudentDialog(s){
      this.current_student=s
      this.$refs.studentDetail.showModal()
    },

    handleLessons(s){

      this.$refs.studentLessons.showStudent(s)
    },

    handleStudentKKSubs(s){
      this.$refs.studentKKSubs.showStudent(s)
    },

    addStudent() {
      this.current_student={}
      this.$refs.studentDetail.showModal()
    },

    importStudents(){
      this.$refs.studentImport.showModal()
    },

    handleStudentsImported(){
      this.loadStudents()
    }
  }
}
</script>

<style scoped>

</style>
<script>
import readXlsxFile from "read-excel-file";
import axios from "axios";

export default {
  name: "partnersImport",
  data() {
    return {
      partner_company: "rentel",
      rows:[],
        courses:[]
    };
  },
  mounted() {
    this.loadCourses()
    const input = document.getElementById('input')
    input.addEventListener('change', () => {
        this.items=[]
      readXlsxFile(input.files[0]).then((rows) => {
        this.rows = []
        for( let i in rows){
            const c = this.detect_course(rows[i][4])
            this.rows.push({
              email: rows[i][3],
              name: rows[i][1],
              surname: rows[i][2],
              partner_id: rows[i][0],
              import_courses_helper: c
            })
        }
      }).catch(ex =>{
          console.error( ex)
          this.$toasted.show(ex.toString() , {type: 'error'});
      })
    })
  },

  methods:{
    handleRunImport(){
      const data={
        partner_company: this.partner_company,
        rows: this.rows
      }
      axios.post('/partners_import/import', data).then(response => {
          let u = response.data.updated
          let n = response.data.new_students
          this.$toasted.show(`Import proběhl, ${u} studentů se aktuializovalo, ${n} nových se vytvořilo.`, {type: 'success'});
      }).catch((r)=>{
          this.$toasted.show('chyba: '+ r.response.data.error , {type: 'error'});
      })
    },
    detect_course(course_name){
            for( let i=0; i < this.courses.length; i++ ){
                const cc =  this.courses[i]
                if (cc.id == course_name || cc.name.trim() == course_name.trim()){
                    return cc.id
                }
            }
            return null
    },
    loadCourses(){
        const _this= this
        axios.get('/api/courses').then( response =>{
              this.courses = response.data.courses
        })
    }
  }
};
</script>

<template>
  <div>


      <div class="row">
          <div class="col-md-2">
              <select v-model="partner_company" class="form-control">
                  <option value=""></option>
                  <option>rentel</option>
                  <option>glossa</option>
              </select>
          </div>
          <div class="col-md-4">
              <input type="file" id="input" class="form-control"/>
          </div>
      </div>



      <table class=" table table-striped">
          <thead>
            <tr>
                <th>ID</th>
                <th>email</th>
                <th>name</th>
                <th>surname</th>
                <th>kurzy</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in rows">
                <td>{{r.partner_id}}</td>
                <td>{{r.email}}</td>
                <td>{{r.name}}</td>
                <td>{{r.surname}}</td>
                <td><select v-model="r.import_courses_helper">
                        <option v-for="c in courses" :value="c.id">{{c.name}}</option>
                    </select>
                </td>
            </tr>
          </tbody>
      </table>
      <button class="btn btn-primary" @click.prevent="handleRunImport">Spustit import </button>

  </div>
</template>

<style scoped></style>
<template>
  <div>
    <div class="d-flex shopping-cart">
      <div class="ml-auto" style="width: 555px">
        <div class="logo">
          <img src="/img/logo-el.svg" />
        </div>

        <div class="hh">Vaše objednávka</div>
        <table class="table ">
          <thead>
            <tr>
              <th colspan="2">Produkt</th>
                <th></th>
              <th>Mezisoučet</th>
            </tr>
          </thead>
          <tr v-for="it in items">
              <td>
                  <a class="remove-item">x</a>
              </td>
            <td>
              <img :src="it.product.small_image_url" class="product-image" />
            </td>
            <td>{{ it.product.name }}</td>
            <td>{{ it.product.price_czk }}</td>
          </tr>


            <tr>
                <th colspan="3">Cena celkem</th>

                <th>2 490 Kč</th>
            </tr>
        </table>

          <div class="extra-blok">
              <div class="sipka"></div>
              <div class="extrablok-body">
                <div class="chci-cbb">
                  <cb-gr />  Chci navíc předplatné konverzačních klubů angličtiny jen za 490 Kč
                </div>


                  <img data-v-16670798="" src="https://www.onlinejazyky.cz/files/mod_eshop/produkty/nahled/234-anglictina-pro-zacatecniky-a1.png" class="product-image">
                  Angličtina pre pokročilých
              </div>

          </div>



      </div>



      <div class="meziblok">

      </div>

      <div class="mr-auto right-block" style="width: 720px; ">
        <div id="shoping-cart-step-1" v-if="!payment_id">
          <div class="billing-fields row">
            <div class="col-md-12">
              <div class="hh">Fakturační údaje</div>
            </div>

              <div class="col-md-12">

                  <label for="billing_email" class="">Email&nbsp;<abbr class="required" title="vyžadováno">*</abbr></label>
                  <span class="woocommerce-input-wrapper"><input     type="email"   class="input-text"      placeholder=""  v-model="billing_email" autocomplete="email username" /></span>

              </div>

              <div class="col-md-6"   id="billing_first_name_field">
                  <label for="billing_first_name" class="">Jméno&nbsp;<abbr class="required" title="vyžadováno">*</abbr></label>
                  <span class="woocommerce-input-wrapper"><input type="text" class="input-text"  v-model="billing_first_name"    autocomplete="given-name" /></span>
              </div>

              <div class="col-md-6"  id="billing_last_name_field">
                <label for="billing_last_name" class="" >Příjmení&nbsp;<abbr class="required" title="vyžadováno" >*</abbr></label >
                  <input   type="text"  class="input-text"  v-model="billing_last_name" placeholder="" autocomplete="family-name"  />
              </div>

            </div>

            <div class="text-center mt-3">
              <a href="#" @click.prevent="company_box=!company_box">Koupit na firmu</a>
            </div>

            <div class="row" v-if="company_box">
                    <div class="col-md-12" id="billing_company_field" data-priority="">
                        <label for="billing_company" class="screen-reader-text"
                        >Společnost&nbsp;<span class="optional"
                        >(volitelný)</span
                        ></label><span class="woocommerce-input-wrapper" ><input type="text"  class="input-text"  v-model="billing_company"  id="billing_company"  placeholder="Společnost"  value=""/></span>
                    </div>

                    <div class="col-md-6"               id="billing_ic_field"            data-priority="31" >
                        <label for="billing_ic" class="">IČO&nbsp;<span class="optional">(volitelný)</span></label>
                        <span class="woocommerce-input-wrapper"><input  type="text" class="input-text" v-model="billing_ic"  placeholder="IČO" /></span>
                    </div>
                    <div class="col-md-6" id="billing_dic_field" data-priority="31">
                      <label for="billing_dic" class="">DIČ&nbsp;<span class="optional">(volitelný)</span></label>
                        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing_dic" id="billing_dic"  placeholder="DIČ" value=""/></span>
                    </div>

                    <div class="col-md-6" id="billing_dic_dph_field" data-priority="31">
                      <label for="billing_dic_dph" class="">IČ DPH&nbsp;<span class="optional">(volitelný)</span></label><span class="woocommerce-input-wrapper"
                        ><input  type="text" class="input-text" v-model="billing_dic_dph" placeholder="IČ DPH" /></span>
                    </div>
            </div>


          <div class="row">

              <div class="col-md-12">
                  <hr>
              </div>

            <div class="wcf-coupon-col-1 col-md-6">

                <input
                  type="text"
                  v-model="discount_code"
                  class="input-text wcf-coupon-code-input"
                  placeholder="Kód kupónu"
                  id="coupon_code"
                  value=""
                />

            </div>
            <div class="col-md-6">
              <span>
                <button          type="button"
                                 class="button-usecode "     @click.prevent="applyCouponCode"   >
                  Použít  <img src="/img/_arrow-right.svg" style="height: 25px; position: relative; top:-2px;"/>
                </button>
              </span>
            </div>

              <div class="col-md-12">
                  <hr>
              </div>


              <div class="hh">
                  Platba kartou (GoPay)
              </div>

          </div>

          <button @click.prevent="handle_create_crder">Objednat</button>
        </div>
      </div>
    </div>

    <set-password></set-password>
    <pre>
d


</pre>

    HU

    <p><%=@objid %></p>

    <a href="?add=885">add 885</a>
    <a href="?add=532">add 532</a>
  </div>


</template>

<script>
import axios from "axios";
import SetPassword from "./set-password.vue";
import CbGr from "./cb-gr.vue";

export default {
  props: ["objid"],
  components: { SetPassword , CbGr  },
  name: "shopping-cart",
  data() {
    return {
      company_box: false,
      items: [],
      billing_email: "",
      billing_first_name: "",
      billing_last_name: "",
      billing_ic: "",
      billing_dic: "",
      billing_dic_dph: "",
      billing_company: "",
      discount_code: "",
      payment_id: null,
      currency: 'CZK',

      order_id: null,
    };
  },
  mounted() {
    this.loadCart();
  },
  methods: {
    loadCart() {
      axios.get(`/shopping_cart/cart`).then((response) => {
        this.items = response.data.items;
      });
    },

    applyCouponCode(){
      const data={
        code: this.discount_code,
        objid: this.objid,
        currency: this.currency
      }
      axios.post('/shopping_cart/discount-code', data).then((response) => {

      })
    },

    handle_create_crder() {
      const data = {
        billing: {
          email: this.billing_email,
          first_name: this.billing_first_name,
          last_name: this.billing_last_name,
          ic: this.billing_ic,
          dic: this.billing_dic,
          dic_dph: this.billing_dic_dph,
          company: this.billing_company,
        },
        discount_code: this.discount_code,
        objid: this.objid,
      };
      axios.post(`/shopping_cart/create-order`, data).then((response) => {
        this.payment_id = response.data.payment.id;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.product-image {
  max-width: 100px;
}

.input-text {
  border-radius: 10px;
  border: 1px solid rgba(208, 208, 208, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 50px;
    width: 100%;
}
.button-usecode {
    box-shadow: 0px 2px 24px rgba(51, 156, 205, 0.395515);
    opacity: 1;

    height:50px; line-height: 51px;

    border-radius: 27.5px;
width: 196px;
    background-image: linear-gradient(90deg, rgba(0, 149, 218, 1) 0%, rgba(19, 144, 174, 1) 100%);
    box-shadow: 0px 2px 0px rgba(0,0,0, 0.15);
    border:none;
    color: rgba(255,255,255,1);

    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.93px;
    text-align: center;
    text-transform: uppercase;

}

.shopping-cart {
    label {
        opacity: 0.5;
        color: rgba(29, 29, 38, 1);

        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 1px;
        text-align: left;
        line-height: 10px;
        text-transform: uppercase;
        margin: 15px 0;
    }


  .meziblok{
    background: #E2F3FB;
    background-image: url("/img/bg-side-light.svg");
    background-repeat: no-repeat;
    background-position: left top;

    width:40px;
  }
  .right-block{
    background: #E2F3FB;
    height: 100%;



    padding:60px;

  }


  .hh{
    opacity: 1;
    color: rgba(51,51,51,1);

    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
  }

  height: 100%;


  .table{
    th{
      border: none;
    }
    td{


      border-top: none;
      border-bottom: 1px dashed rgba(206,206,206, 1);
      opacity: 1;
    }
  }
  .remove-item{
    opacity: 1;
    color: rgba(255,0,0,1);

    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 14px;
  }


  .extra-blok{
    border: 1px solid rgba(231,231,231, 1);
    box-shadow: 0px 2px 10px rgba(51,156,205, 0.200858);
    opacity: 1;
    background-color: rgba(226,243,251, 1);

    min-height: 200px;


    border-radius: 11px;


    position: relative;

     .sipka{
       border-radius: 1px;
       opacity: 1;
        background-color: rgba(226,243,251, 1);
       width:21px;
       height:21px;
       position:absolute;
       left:40px; top:-12px;
       z-index: 1;


       transform: rotate(45deg);
       border-top: 1px solid rgba(231,231,231, 1);
       border-left: 1px solid rgba(231,231,231, 1);
     }


    .extrablok-body{
      position: relative;
      z-index: 2;

      margin:10px;
    }

    .chci-cbb{
      border-radius: 6px;
      opacity: 1;
      background-color: rgba(56,168,212, 1);
      padding:0px 10px;



      color: rgba(255,255,255,1);

      font-size: 15px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -0,44px;
      text-align: left;
      line-height: 20px;
      height:40px;

    }

  }
}
</style>

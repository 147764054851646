import { render, staticRenderFns } from "./FGUsers.vue?vue&type=template&id=6a52bf0d&scoped=true&"
import script from "./FGUsers.vue?vue&type=script&setup=true&lang=js&"
export * from "./FGUsers.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a52bf0d",
  null
  
)

export default component.exports
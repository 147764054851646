import {Controller} from "stimulus"
import Fuse from "fuse.js";

export default class extends Controller {
    static targets = ["original", "choices", "search", "selected"]

    connect() {
        console.log(`table select connected_with original: ${this.originalTarget}`)
        console.log(this.originalTarget)

        // create selected choices

        const selected = document.createElement("ol")
        selected.classList.add('multi-list')
        selected.classList.add('selected')

        selected.dataset['target'] = 'table-select.selected'
        this.element.appendChild(selected)

        // create input

        const input = document.createElement("input")
        input.type = 'text'
        input.dataset['action'] = 'input->table-select#filter'
        input.dataset['target'] = 'table-select.search'
        input.setAttribute('placeholder', 'Vyhledávejte psaním') // TODO move prompt to data attribute
        this.element.appendChild(input)

        // create choices

        const choices = document.createElement("ol")
        choices.classList.add('multi-list')
        choices.classList.add('choices')
        choices.dataset['target'] = 'table-select.choices'
        this.element.appendChild(choices)

        // populate lists

        const selectedOptions = this.originalTarget.querySelectorAll('option:checked')
        const unSelectedOptions = this.originalTarget.querySelectorAll('option:not(:checked)')

        console.log(selectedOptions)
        console.log(unSelectedOptions)

        // populate selected choices

        selectedOptions.forEach(option => {
            const li = document.createElement("li")
            li.innerText = option.innerText
            li.dataset.target = 'table-select.item'
            li.dataset.action = 'click->table-select#unselect'
            li.dataset.optionValue = option.value
            selected.appendChild(li)
        })

        unSelectedOptions.forEach(option => {
            const li = document.createElement("li")
            li.innerText = option.innerText
            li.dataset.target = 'table-select.item'
            li.dataset.action = 'click->table-select#select'
            li.dataset.optionValue = option.value
            choices.appendChild(li)
        })

        this.sortChoices()

        const fuseOptions = {
            threshold: 0.4,
            keys: ['text']
        }

        this.searchData = [...this.choicesTarget.querySelectorAll('li')].map(elem => {
            return {
                elem: elem,
                text: elem.innerText
            }
        });
        this.fuse = new Fuse(this.searchData, fuseOptions);
    }

    select(e) {
        const elem = e.target
        elem.dataset.action = 'click->table-select#unselect'
        this.selectedTarget.appendChild(elem)

        this.originalTarget.querySelector(`option[value="${elem.dataset.optionValue}"]`).selected = true
    }

    unselect(e) {
        const elem = e.target
        elem.dataset.action = 'click->table-select#select'
        this.choicesTarget.appendChild(elem)

        this.originalTarget.querySelector(`option[value="${elem.dataset.optionValue}"]`).selected = false
        this.sortChoices();
    }

    sortChoices() {
        console.log("sorting")

        const elems = [].slice.call(this.choicesTarget.children)

        elems.sort((a, b) => {
            return a.innerText.toLowerCase().localeCompare(b.innerText.toLowerCase());
        }).forEach(elem => {
            this.choicesTarget.appendChild(elem)
        })
    }


    filter(e) {
        const query = this.searchTarget.value
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            if(query.length === 0){
                this.searchData.forEach(searchItem => {
                    const elem = searchItem.elem;
                    if(document.contains(elem) && !this.choicesTarget.contains(elem)) return;

                    this.choicesTarget.appendChild(elem);
                })
                return;
            }

            this.choicesTarget.querySelectorAll('li').forEach(elem => {
                elem.remove();
            })
            console.log(`looking for: ${query}`)
            this.fuse.search(query).map( result => {
                const item = result.item;
                const elem = item.elem;
                if(document.contains(elem) && !this.choicesTarget.contains(elem)) return;

                this.choicesTarget.appendChild(elem);
            });
        }, 1000);
    }

}

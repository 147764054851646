<template>


  <div class="row wf-condition">
    <!--<div class="col-md-3">{{condition}}</div> -->
    <div class="col-md-1">
      <div class="btn-group segment-op" role="group" v-if="condition.segment_operator">
        <button type="button" :class="`btn btn-smallish ${condition.segment_operator=='AND' ? 'btn-success' : 'btn-secondary'}`" @click="condition.segment_operator='AND'">AND</button>
        <button type="button" :class="`btn btn-smallish ${condition.segment_operator=='OR' ? 'btn-success' : 'btn-secondary'}`" @click="condition.segment_operator='OR'">OR</button>
      </div>
    </div>
    <div class="col-md-2">
    <select class="form-control" v-model="condition.attr_name" @change="condition.text_value=''">
      <optgroup label="Student">
        <option value="language_id">Komunikační jazyk</option>
        <option value="email">E-mail</option>
        <option value="name">Jméno</option>
        <option value="surname">Příjmení</option>
        <option value="b" disabled>Narozeni</option>
        <option value="profile_image">Profilovy obrazek</option>
        <option value="source_kind">Odkud je student</option>
        <option value="city">Město</option>
        <option value="country">Země</option>
      </optgroup>
      <optgroup label="Studium">

        <option value="today_time">dnesni doba studia v sekundach</option>

        <option value="days_study" >Dny studia</option>
        <option value="days_no_study" >Dny nestudia</option>
        <option value="streak">Aktuální počet dní v řadě</option>
        <option value="streak_record">Maximální počet dní v řade</option>
        <option value="points">Počet bodů</option>
        <option value="level">Level</option>
        <option value="badge" disabled>Badge</option>
        <option value="active_course_id">Aktivní kurz</option>
        <option value="last_usage">Poslední použití</option>

      </optgroup>

      <optgroup label="Marketing">
        <option value="student_typ">Typ uživatele</option>

      </optgroup>
      <optgroup label="Date & Time">
        <option value="current_y">Current year</option>
        <option value="current_m">Current month</option>
        <option value="current_mday">Current day of the month</option>
        <option value="current_wday">Current day of the week</option>
        <option value="current_t">Current time</option>
      </optgroup>

    </select>

    </div>

    <div class="col-md-1" v-if="condition.attr_name =='last_usage'">
      <select v-model="condition.usage_kind" class="form-control" >
        <option value="usage_app" disabled>App</option>
        <option value="usage_desktop" disabled>Desktop</option>
        <option value="usage_any">Any</option>
      </select>

    </div>

    <div class="col-md-2">
      <select class="form-control" v-model="condition.operator">
        <option v-for="o in operators" :key="o.value" :value="o.value">{{o.name}}</option>
      </select>

    </div>


      <div v-if="condition.attr_name=='source_kind' && ['is', 'not'].indexOf(condition.operator)>=0" class="col-md-2">
        <select class="form-control" v-model="condition.source_kind">
          <option value="company">Firma</option>
          <option value="school">Škola</option>
          <option value="language_school">Jazykovka</option>
          <option value="eshop">E-shop</option>
        </select>
      </div>

      <div class="col-md-2" v-if="condition.source_kind=='eshop'">
        <v-select
                  multiple
                  v-model="condition.eshops"
                  :reduce="c => c.symbol"
                  label="json_name"
                  :options="eshops"></v-select>
      </div>


        <div class="col-md-2  ll" v-if="condition.attr_name!='last_usage'">

          <div v-if="condition.attr_name == 'source_kind' && condition.source_kind=='company'">
            <input type="text" v-model="condition.text_value" class="form-control"/>
          </div>

          <div v-if="condition.attr_name=='current_y'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="l in possibleYears" :key="l" :value="l">{{l}}</option>
            </select>
          </div>

          <div v-if="condition.attr_name=='current_m'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="l in 12" :key="l" :value="l">{{l}}</option>
            </select>
          </div>

          <div v-if="condition.attr_name=='current_t'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="l in 24" :key="l" :value="l-1">{{l-1}}:00</option>
            </select>
          </div>

          <div v-if="condition.attr_name=='current_mday'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="l in 31" :key="l" :value="l">{{l}}</option>
            </select>
          </div>

          <div v-if="condition.attr_name=='current_wday'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="l in wdays" :key="l.value" :value="l.value">{{l.name}}</option>
            </select>
          </div>


          <div v-if="condition.attr_name=='language_id'">
            <select class="form-control" v-model="condition.int_value">
              <option v-for="(k, v) in languages" :key="k" :value="k">{{v}}</option>
            </select>
          </div>

          <div v-if="['email', 'name', 'surname', 'city', 'country', 'badge'].indexOf(condition.attr_name)>=0 && condition.operator!='blank' && condition.operator!='not-blank'">
            <input type="text" class="form-control" v-model="condition.text_value"/>
          </div>

          <div v-if="['days_study', 'days_no_study', 'streak', 'streak_record', 'points', 'level', 'today_time'].indexOf(condition.attr_name)>=0" >
            <input type="number" class="form-control" v-model="condition.int_value"/>
          </div>




          <div v-if="condition.attr_name==`student_typ`" >
            <v-select multiple v-model="condition.statuses"
                      :reduce="country => country.code"
                      :options="[
                {label: 'Pouze registrovaný', code: 'reg'},
                 {label:'Trialista', code:'trial'},
                {label: 'Zákazník', code: 'customer'},
                {label: 'Bývalý zákazník', code: 'past_customer'},
                {label: 'Předplatitel', code: 'subscriber'}

               ]"></v-select>
          </div>
        </div>

      <div class="col-md-4">


        <div v-if="['points', 'level'].indexOf(condition.attr_name)>=0">
          <select v-model="condition.language_value" class="form-control w-auto" placeholder="Jazyk">
            <option>cs</option>
            <option>de</option>
            <option>en</option>
            <option>fr</option>
            <option>it</option>
            <option>es</option>
            <option>ru</option>
            <option>pl</option>
          </select>
        </div>


      <div v-if="condition.attr_name==`student_typ`" >
        Kurzy
        <select v-model="condition.student_typ_course" class="form-control">
          <option value="any">Any</option>
          <option value="selected_scourse">Konkrétní kurz</option>
        </select>


        <select-course v-if="condition.student_typ_course ==`selected_scourse`"
                       :courseId="condition.course_id"
                       @couseSelect="onStudentTypCourseSelect"
          ></select-course>

        <div v-if="condition.student_typ_course ==`any`">
          Jazykova kategorie

          <v-select multiple v-model="condition.language_category"
                    :options="['All',  'cs',  'en', 'de', 'fr', 'es', 'ru', 'it', 'sk', 'pl']"></v-select>
        </div>
      </div>


      <select-course :courseId="condition.course_id"
                     v-if="condition.operator == 'course' && condition.attr_name==`active_course_id`"
                     @couseSelect="onCourseSelect"
                     ></select-course>


<!--
      <div v-if="['days_study','days_no_study', 'streak',  'points',`student_typ`, 'last_usage'].indexOf(condition.attr_name)>=0 " >
        <select class="form-control" v-model="condition.date_compare">
          <option value="reg">Od dne registrace</option>
          <option value="custom_date">Od konkrétního data</option>
          <option value="current_date">Aktuálního data</option>
        </select>
      </div>
        -->

    </div>

      <div v-if="condition.attr_name==`last_usage`"  class="col-md-2 textv">
        <input type="date" v-model="condition.text_value"   class="form-control"/>
      </div>




  </div>
</template>

<script>

import SelectCourse from "../select-course";
const possible_operators={
  language_id: ['is', 'not'],
  email: ['is', 'not','contain','not-contain'],
  name: ['is', 'not','contain','not-contain','blank', 'not-blank'],
  surname:['is', 'not','contain','not-contain','blank', 'not-blank'],
  b:[],

  profile_image:['blank', 'not-blank'],

  source:['is', 'not','contain','not-contain','blank', 'not-blank'],

  city:['is', 'not','contain','not-contain','blank', 'not-blank'],
  country:['is', 'not','contain','not-contain','blank', 'not-blank'],

  today_time: ['is', 'not', '>', '<'],
  days_study: ['is', 'not', '>', '<'],
  days_no_study: ['is', 'not', '>', '<'],
  streak: ['is', 'not', '>', '<'],
  streak_record: ['is', 'not', '>', '<'],
  points: ['is', 'not', '>', '<'],
  level: ['is', 'not', '>', '<'],
  active_course_id: ['blank', 'course'],
  last_usage: ['is', 'not', 'before', 'after', 'blank'],
  student_typ: ['is', 'not'],
  badge: ['is', 'not'],
  source_kind: ['is', 'not', 'blank', 'not-blank'],
  current_y: ['is', 'not', 'before', 'after',  'obefore', 'oafter'   ],
  current_m: ['is', 'not', 'before', 'after',  'obefore', 'oafter'   ],
  current_mday: ['is', 'not', 'before', 'after',  'obefore', 'oafter'   ],
  current_wday: ['is', 'not', 'before', 'after',  'obefore', 'oafter'  ],
  current_t: ['is']
}

const operator_names={
  is: 'is',
  not: 'is not',
  contain: 'Contains',
  'not-contain': 'Does not Contain',
  blank: 'Blank',
  'not-blank': 'Not blank',
  '>': 'is higher',
  '<': 'is lower',
  'course': 'Kurz' ,
  'before': 'is before',
  'after': 'is after',
  'obefore': 'is on or before',
  'oafter': 'is on or after'
}
import moment from "moment/moment";
export default {
  name: "condition",
  components: {SelectCourse},
  props:['condition', 'eshops'],
  data(){
    return {

      languages: {
        'CZ': 1,
        'SK':8,
        'PL': 10,
        'EN': 2,
        'ES': 5
      },
      attr_name:'',
      operator:'',
      language_value:'CZ',
      text_value:'',
      selected_course: null,
      student_typ_selected_course:null
    }
  },
  watch:{

  },
  computed:{
    possibleYears(){
      let y = moment().format('YYYY')
      let arr=[]
      y = parseInt(y)+1
      for(let n=0; n<10; n++){
        arr.push(y)
        y-=1
      }
      return arr
    },
    operators(){

      const ops = []

      const arr = possible_operators[this.condition.attr_name] || [];

      for(var i in arr){
        let k = arr[i]
        ops.push({name: operator_names[k], value: k, date_compare:'reg'})
      }

      return ops
    },

    wdays(){
      let d = moment().startOf('week')
      let arr=[]

      for (let n=0; n<7; n++) {

        arr.push({
          value: n,
          name: d.format('dddd')
        })
        d = d.add(1, 'days')

      }
      return arr
    }

  },
  methods:{
    onCourseSelect(a){
      this.selected_course = a
      this.condition.course_id = a.id
    },

    onStudentTypCourseSelect(a){
      this.student_typ_selected_course = a
      this.condition.course_id = a.id
    }
  }
}
</script>

<style scoped lang="scss">

  .wf-condition{
      margin-bottom: 15px;
      .segment-op{
        margin-top: 3px;
      }

    padding-bottom: 1em;

      border-bottom: 1px solid #0086de;


  }

</style>
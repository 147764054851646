<template>
  <span>

    <button class="btn btn-outline-primary" @click="$modal.show('modall')">hromadné prodloužení expirace</button>


      <modal name="modall" :resizable="true" :adaptive="false" :width="500">
        <div class="m-head p-3">
          <a class="close"  href=# @click.prevent="$modal.hide('modall')"><span class="material-icons-outlined">close</span></a>
          <h4>hromadné prodloužení expirace</h4>
        </div>

        <div class="modbod p-3">
          <div class="d-flex">
            <input type="date" id="batch-date-preset" class="form-control" style="" v-model="batch_expiration" />
            <button class="btn-outline-info btn btn-sm" @click="update_expirations">Nastavit</button>
          </div>
        </div>
      </modal>
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "organization-helpers",
  props:['org'],
  data(){ return {
      batch_expiration: ''
    }
  },
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
  },
  methods:{
    update_expirations(){
      const url = `/organizations/${this.org.id}/assignments/batch-update-expiration`
      axios.put(url, { date: this.batch_expiration }).then(response => {

        if (response.data.asigns){
          this.$toasted.show(`Nastavilo se ${response.data.asigns.length} přiřazení.`, {type: 'success'});
          this.$modal.hide('modall')

        }else{
          this.$toasted.show('nic', {type: 'error'});
        }

      }).catch((r)=>{
        this.$toasted.show('chyba: '+ r.response.data.error , {type: 'error'});
      })
    }
  }
}
</script>

<style scoped>

</style>
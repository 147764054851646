<script>
import ProfileImage from "./profile-image.vue";
import modalKkMixin from "./modal-kk-mixin";

import axios from "axios";
export default {
  name: "app-lektor-profil",
  components: {ProfileImage},
  mixins:[modalKkMixin],
  watch:{
  /*  user(){
      this.showM=true
      if (this.user.kk_levels.indexOf('1')>=0)
        this.l1=true
      else
        this.l1=false

      if (this.user.kk_levels.indexOf('2')>=0)
        this.l2=true
      else
        this.l2=false

      if (this.user.kk_levels.indexOf('3')>=0)
        this.l3=true
      else
        this.l3=false

    }*/
  },
  data(){
    return {
      user:{},
      avatar: '',
      l1:false, l2:false, l3:false
    }
  },

  mounted() {
    this.loadLectorProfil()
  },

  methods:{

    loadLectorProfil(){
      axios.get(`/conversation_club/lectors/me`).then(response => {
        this.user = response.data.user

      })
    },


    handleSaveChanges(){
      const formData = new FormData()
      if (this.user.id && this.user.id >0 ) {
        formData.append('id', this.user.id)
      }

      let levels=[]
      if (this.l1) levels.push(1)
      if (this.l2) levels.push(2)
      if (this.l3) levels.push(3)


      formData.append('user[last_name]', this.user.last_name)
      formData.append('user[first_name]', this.user.first_name)
      formData.append('user[email]', this.user.email)
      formData.append('user[kk_levels]', levels.join(','))
      if (this.avatar) {
        formData.append("user[profile_image]", this.avatar, this.avatar.name)
      }
      if (this.user.password != null && this.user.password_confirmation == this.user.password){
        formData.append('user[password]', this.user.password)
      }

      axios.post('/conversation_club/lectors/update-current-user', formData).then(response =>{
        this.user.profile_image_url = response.data.profile_image
        this.$toasted.show("Změny uloženy", {type: 'success'});

      })
    },
    handleImageChange(f){
      this.avatar= f
    }
  }

}
</script>

<template>
<div>
    <div class="modal__content">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Jméno</label>
            <input type="text" v-model="user.first_name" class="form-control" />
          </div>

          <div class="form-group">
            <label>příjmení</label>
            <input type="text" v-model="user.last_name" class="form-control" />
          </div>

          <div class="form-group">
            <label>e-mail</label>
            <input type="text" v-model="user.email" class="form-control" disabled="true"/>
          </div>

          <div class="form-group">
            <label>heslo</label>
            <input type="password" v-model="user.password" class="form-control" />
            <p class="small "><i>Změní se jen při vyplnění hesla a potvrzení hesla</i></p>
          </div>

          <div class="form-group">
            <label>potvrzení hesla</label>
            <input type="password" v-model="user.password_confirmation" class="form-control" />
          </div>


        </div>

        <div class="col-md-6">
          <profile-image @imageChange="handleImageChange" :src="user.profile_image_url_big"></profile-image>
        </div>

      </div>



    </div>

    <div class="mt-3">
      <button  class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="hideModal">zrušit</button>
    </div>



</div>
</template>

<style scoped>

</style>
<script setup>
import './fe.scss'
import axios from "axios";
import FInputText from "./fields/FInputText.vue";
import FInputDate from "./fields/FInputDate.vue";
import FInputCategory from "./fields/FInputCategory.vue";
import FUsers from "./fields/FUsers.vue";
import AppTable from "./AppTable.vue";
export default {
  name: 'form-generator-app',
  components:{FInputText, FInputDate, FInputCategory, AppTable, FUsers},
  mounted() {

    this.slug = location.href.split("/").pop()

    this.loadFormApp(this.slug)

  },
  data(){
    return{
      vv: 'table',
      ch:5000,
      slug:'',
      app:{},
      fields:[],
      item:{}

    }
  },

  methods: {
    handleSaveChanges(){
      const data = { item: this.item }
      if (this.item.id>0) {
        axios.put(`/formgenerator/app/${this.slug}/${this.item.id}`, data)
      }else{
        axios.post(`/formgenerator/app/${this.slug}`, data).then(response => {
          this.item.id = response.data.item.id
        })

      }
    },


    loadFormApp(id) {
      axios.get(`/formgenerator/forms/${id}`).then(response => {
        this.fields = response.data.fields
        this.app = response.data.app

        for(let i in this.fields){
          let f = this.fields[i]
          //this.item[f.codename] = ''
        }

      })

    },

    handleAddItem(){
      this.item={}

      for(let i in this.fields){
          let f = this.fields[i]
          this.item[f.codename] = null
      }

      this.vv = 'form'
    },
    handleCancelForm(){
      this.vv='table'
    },

    itemClick(it){
      this.item = it
      this.vv = 'form'

    }
  }

}
</script>

<template>
<div class="big-blok">
  <div :key="ch">[[  {{item}} ]]</div>

  <div v-if="app.id && vv =='table'">
    <button @click.prevent="handleAddItem">new item</button>
    <AppTable :fields="fields" :app="app" @itemClick="itemClick" />
  </div>
  <div v-else>
     <div v-for="f in fields">
        <FInputText :options="f" v-if="f.type=='text'" :item="item"></FInputText>

        <FInputDate :options="f" v-if="f.type=='date'"  :item="item" ></FInputDate>

       <FUsers :options="f" v-if="f.type=='user'"  :item="item" @change="ch++"></FUsers>

        <FInputCategory :options="f" v-if="f.type=='category'" :item="item" @change="ch++"></FInputCategory>
      </div>



    <hr>
    <button class="btn btn-success" @click.prevent="handleSaveChanges">Save changes</button>
    <button class="btn btn-outline-dark" @click.prevent="handleCancelForm">cancel</button>

  </div>
  <hr>
  <div> <a :href="`/formgenerator/editor/index#${app.codename}`" >Modify Template</a> </div>
</div>
</template>

<style scoped>

</style>
<template>
  <div>
    <a @click.prevent="openModal" href="#" class="">API Log</a>

 <modal name="modal1" :resizable="false" :adaptive="true" :height="550" :width="700">

    <div class="m-head">
      <a class="close"  href=# @click.prevent="$modal.hide('modal1')"><span class="material-icons-outlined">close</span></a>
    </div>

   <div class="seznam">
    <div v-for="a in log" class="logitem">
      <h5>{{a.file}}</h5>
      <div><pre>{{a.data}}</pre></div>
    </div>

   </div>
 </modal>


  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "woo-api-log",
  props: ['order'],
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
  },
  data(){
    return({
      log: []
    });
  },
  methods:{

    openModal(){
      this.$modal.show('modal1')

        const url = `/orders/${this.order.id}/woo-log`
        axios.get(url).then(response => {
          this.log=response.data.items
        })

    }

  }


}
</script>

<style scoped lang="scss">
 .logitem{
   h5{
     background: #730E15;
     color: #fff;
     margin:0;
     padding:3px;
   }
   pre{
     margin-bottom:10px;
     background: #eeefff;
   }
 }

 .seznam{
   overflow-y: scroll;
   height: 500px;
   margin-top:40px;
   margin-right: 5px;
   margin-left:5px;
 }

</style>
import {Controller} from "stimulus"

export default class extends Controller {
  static values = {
    message: String
  }

  display() {
    alert(this.messageValue)
  }
}

<script>

export default {
  name: "OjPagination",

  props:['totalItems','currentPage','perPage'],
    // @pageChanged="pageChanged"

  data(){
    return {
      p: this.currentPage
    }
  },

  methods:{
    changePage(p){
      this.p = p
      this.$emit('pageChanged', p)
    },
    linkItemCount(){
      return Math.ceil(this.totalItems / this.perPage)
    }
    ,
    previous(){
      if (this.p > 1) {
        this.p -= 1;
        this.$emit('pageChanged', this.p)
      }
    },
    nextp(){
      if (this.p < this.linkItemCount()) {
        this.p++
        this.$emit('pageChanged', this.p)
      }
    }
  }
}
</script>

<template>
<nav aria-label="Page navigation">
  <ul class="pagination" v-if="totalItems>perPage">
    <li :class="`page-item ${p<=1 ? 'disabled' : ''}`"><a class="page-link" href="#" :disabled="p<=1" @click.prevent="previous"><span aria-hidden="true">&laquo;</span></a></li>
    <li :class="`page-item ${p == n ? 'active':''}`" v-if="n-p<15  && p-n <15 "
        v-for="n in linkItemCount()"><a class="page-link"  href="#" @click.prevent="changePage(n)">{{n}}</a></li>
    <li :class="`page-item ${p >= linkItemCount() ? 'disabled':''}`"><a class="page-link" href="#" :disabled="p >= linkItemCount()" @click.prevent="nextp"><span aria-hidden="true">&raquo;</span></a></li>
  </ul>
</nav>
</template>

<style scoped>

</style>
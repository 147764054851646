<template>
    <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f mcw1">

        <div class="modal-header">
            <span class="modal__title">Detail lekce</span>
            <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>



        </div>

        <div class="modal__content" >
            <div class="row">
                <div class="col-md-2">Úroveň kurzu</div>
                <div class="col-md-10">{{lesson.level }}</div>

                <div class="col-md-2">                Datum</div>
                <div class="col-md-10">{{ df(lesson.day) }}</div>

                <div class="col-md-2">                Čas</div>
                <div class="col-md-10">    {{lesson.hour}}:00</div>

                <div class="col-md-2">                Téma</div>
                <div class="col-md-10">{{lesson.topic_name }}</div>


            </div>


            <div class="row">
                <div class="col-md-2">Lektor: </div>
                <div class="col-md-10">{{lesson.lector_name}}
                    <span v-if="lesson.native_speaker"><br> Rodilý mluvčí</span>
                </div>
            </div>


          <div class="row" v-if="lesson.join_url">
            <div class="col-md-2">  Připojit na lekci </div>
            <div class="col-md-10"><a :href="lesson.join_url" target="_blank">{{lesson.join_url }}</a></div>
          </div>


 <div class="mt-3 d-flex">
     <h5>Studenti ({{lesson_students2.length}}/{{lesson.capacity}})</h5>
     <div class="ml-auto"><button class="btn btn-primary"
                                  @click.prevent="handleSubscribeLesson"
                                  v-if="!lessonSubscribed && volnaPole()>0 && isFutureLesson && !jsem_hocikde">Rezervovat</button>
     </div>
 </div>
            <table class="table table-striped mt-2">
                <tr v-for="s in lesson_students2">
                    <td :class="`text-center ${s.id == student_id ? 'tosemja': ''}`">{{s.nickname ? s.nickname : `student ${s.id}`}} </td>
                    <td style="width:1%">
                        <button v-if="s.id == student_id && isFutureLesson" @click.prevent="handleUnsubscribeLesson" class="btn btn-danger">Zrušit rezervaci</button>
                    </td>
                </tr>

                <tr v-for="i in volnaPole()"  >
                    <td class=" text-center"><i>volné</i></td>
                    <td></td>

                </tr>

            </table>
        </div>
    </vue-final-modal>
</template>

<script>
import LevelSwitch from "./level-switch.vue";
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "./modal-kk-mixin";
export default {
  props:['lesson', 'close', 'wdays', 'lesson_students', 'student_id', 'jsem_hocikde'],
  name: "lesson-detail-student",
    mixins:[modalKkMixin],
  components: {LevelSwitch, VueFinalModal},
    watch:{
        lesson_students(){
            this.lesson_students2 = this.lesson_students
        }
    },
    data(){
      return {
          lesson_students2:[]
      }
    },
    computed:{
        lessonSubscribed(){
            for(let i in this.lesson_students2){
                if (this.lesson_students2[i].id == this.student_id) return true
            }
            return false
        },
        isFutureLesson(){
            let tl = moment(`${this.lesson.day} ${this.lesson.hour}:00:00`).unix()
            let ted = moment().unix()
            return (ted < tl)

        }
    },
  methods:{

      volnaPole(){
          if (!this.lesson.capacity) return 0
          if (parseInt(this.lesson.capacity)<=this.lesson_students2.length) return 0
          return parseInt(this.lesson.capacity) - this.lesson_students2.length
      },
      handleUnsubscribeLesson(){
          axios.delete(`/conversation_club/students/unsubscribe_lesson/${this.lesson.id}` ).then(response =>{
                this.lesson_students2 = response.data.students
                this.$emit('subscriptionsChanged', response.data.students, false)
          })
      },
      handleSubscribeLesson(){
          axios.post(`/conversation_club/students/subscribe_lesson/${this.lesson.id}` ).then(response =>{
              this.lesson_students2 = response.data.students
              this.$emit('subscriptionsChanged', response.data.students, true)
          }).catch(err => {
            this.$toasted.show(`Chyba: ${err.response.data.error}`, {type: 'error'});

          })



      }
  }

}
</script>

<style scoped>
    td.tosemja{ font-weight: bold}
</style>
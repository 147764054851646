<template>
<span clss="text-left" >
  <i @click="show_student_modal()">

  </i>

  <modal name="example" :resizable="true" :adaptive="false" :height="500">
    <div class="modbod">

        <pre :key="aa">{{ student_info }}</pre>
      </div>
  </modal>
</span>
</template>

<script>

const cols=[

"id", "name",  "surname","email", "mailing","status","created_at", "updated_at","id_study","active_campaign_contact_id",
    ,"entry_form_done",
    "monthly_allowance",
    "language_id",
    "courses_dirty",
    "ac_profile_dirty",
    "ac_courses_dirty",
    "affiliate_partner",
    "affiliate_id",
    "brand",
    "new_trial",
    "new_course",

    "valid_email",
    "ac_sync_failed",
    "profile_dirty",
    "source",
    "partner_id",
    "partner_company",
    "new_gift",
    "allow_sending_credentials",
    "sort_order",
    "should_send_activation", 'courses_helper'

]

export default {

  name: "StudentsTable",
  data(){
    return({
      aa:111,

      student_info:{}
    })
  },
  computed:{

  },
  methods:{
    show_student_modal(student){
      this.$modal.show('example')
      this.student_info_gen(student)
      this.aa++;
    }
    ,student_info_gen(student){

      var arr={}
      for (let n in cols){
        let c = cols[n]
        //console.log('c', c, this.student[c])
        arr[c] = student[c];
      }
      this.student_info=student  // arr
      return arr
    }
  }
}
</script>

<style scoped>
.modbod{
  text-align: left;
  height:450px;
  overflow-y: scroll;
  margin:20px;
  background: #e0e0e0;
}
</style>
<script setup>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "../conversation-club/modal-kk-mixin";
export default {
  name: "fg-remove-field",
  mixins: [modalKkMixin],
  methods:{
    btnRemove(){

    }
  }
}

</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Remove field?</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>

    <div class="modal__content">

      <p>Are you sure you want to remove this field? Removing it means that it will not be available for any members from now on.</p>

  <p>      <label>Also remove field from existing app items
            <input type="checkbox" />
          </label>
  </p>

      <button @click.prevent="btnRemove" class="btn2 btn-danger">Yes, delete</button>
      <button @click.prevent="hideModal" class="btn2 btn-outline-info">Cancel</button>
    </div>
  </vue-final-modal>

</template>

<style scoped>

</style>
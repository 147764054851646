<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">
    <div class="modal-header">
      <b>Detail tématu</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Název tématu</label>
          <input type="text" v-model="topic.name" class="form-control"/>
        </div>
        <div class="form-group">
          <label>Úroveň kurzu</label>
          <level-switch :level="topic.level" v-on:update="v => {topic.level = v}"></level-switch>
        </div>
      </div>
    </div>



    <div class="modal__action">
      <button  class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="showM=false">zrušit</button>
    </div>

  </vue-final-modal>
</template>

<script>


import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import LevelSwitch from "./level-switch.vue";

export default {
  name: "modal-topic"  ,
  components: { VueFinalModal, LevelSwitch },
  props:['topic'],
  data(){
    return({
      showM: false
    })
  },
  methods:{
    showModal(){
      this.showM=true
    },
    handleSaveChanges(){
      this.updateTopic()
    },
    updateTopic(){
      if (this.topic.id) {
        axios.put(`/conversation_club/topics/update/${this.topic.id}`, {
          topic:{
            name: this.topic.name,
            level: this.topic.level
          }
        }).then(response => {

          this.showM=false
        })
      }else{

        axios.post('/conversation_club/topics/create',{
          topic:{
            name: this.topic.name,
            level: this.topic.level
          }
        }).then(response => {
          this.showM=false
          this.$emit('update')
        })

      }
    }
  }

}
</script>

<style scoped>

</style>
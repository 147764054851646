<template>
  <div>
    <div class="level-filter">
      <a :class="filter_level==1 ? `active`: ''" href="#" @click.prevent="filter_level=1" >Level 1</a>
      <a :class="filter_level==2 ? `active`: ''"  href="#" @click.prevent="filter_level=2">Level 2</a>
      <a :class="filter_level==3 ? `active`: ''"  href="#" @click.prevent="filter_level=3">Level 3</a>
    </div>


<div class="card card-body index p-0">
  <div class="d-flex">
      <div class="ml-auto p-2">
        <button class="btn btn-primary pr-2" @click.prevent="addTopic">
          <span class="material-icons mia">add_circle</span>
          Přidat téma</button>
      </div>
  </div>

 <table class="table table-striped">
        <thead class="thead-light">
          <tr>
            <th scope="col">Téma</th>
            <th scope="col"></th>
            <th scope="col">V rozvrhu</th>
            <th scope="col" colspan="2">Akce</th>
          </tr>
        </thead>
        <draggable v-model="topics" @start="drag=true" @end="dragEnd" tag="tbody">
         <tr v-for="element in topics" :key="element.id">
           <td><span class="badge badge-kk">{{element.numer}}</span></td>
           <td>{{element.name}}</td>
           <td></td>
           <td><a href="#" @click.prevent="handleEditTopic(element)">edit</a></td>
             <td><a href="#" @click.prevent="removeItem(element)"><span class="material-symbols-outlined">delete</span></a></td>
         </tr>
        </draggable>
 </table>


</div>
    <modal-topic :topic="current_topic" ref="modalTopic" @update="loadTopics"></modal-topic>
  </div>
</template>

<script>
import axios from "axios";
import draggable from 'vuedraggable'
import ModalTopic from "./modal-topic.vue";
export default {
  name: "cc-topics",
  components: {
    ModalTopic,
            draggable
  }
  ,
  data(){
    return {
      filter_level:1,
      topics:[],
      current_topic:{}

    }
  },
  watch:{
    filter_level(){
          this.loadTopics();
    }
  },

  async created () {
    await this.loadTopics()
  },
  methods:{
    handleEditTopic(t){
      this.current_topic = t
      this.$refs.modalTopic.showModal()
    },
    async removeItem(t){

      if (confirm(`opravdu smazat ${t.name} ?`)) {


        /*for (let i = 0; i < this.topics.length; i++) {
          if (this.topics[i].id == t.id) {
            this.topics.splice(i, 1)
          }
        }*/

        const response = await axios.delete(`/conversation_club/topics/destroy/${t.id}`)
        this.loadTopics()

      }
    },
    addTopic(){
      this.current_topic = {level: this.filter_level, name:""}
      this.$refs.modalTopic.showModal()
    },

    async loadTopics(){
      const response = await axios.get(`/conversation_club/topics/index.json?level=${this.filter_level}`)
      this.topics = response.data.items
    },


    dragEnd(x){

      let ids=[]
      for (let i in this.topics){
        ids.push(this.topics[i].id)
      }
      console.log('drag end', ids)
      axios.post('/conversation_club/topics/update_order', {ids: ids, level: this.filter_level}).then(response =>{
        this.topics = response.data.items
      })
    }


  }


}
</script>

<style scoped>

</style>
<template>
<div>
  <div @click.prevent="show_students_modal" class="cursor-pointer">
    <div>Fronta synchronizace kurzů {{ info.courses_dirty }}</div>
    <div>Fronta studentů do studovny {{ info.sync_students_in_studovna }}</div>

  </div>

    <modal name="synchro-log-modal" :resizable="true" :adaptive="false" :height="500" width="900">
      <div class="modbod">
        <h5>kurzy</h5>
        <table class="table table-striped">
          <tr>
            <th>Student</th><th>Vytvořen</th>
            <th>Kurzů</th><th>Trialu</th>
            <th>Poslední objednávka</th>
            <th>Poslední přiřazení kurzu</th>
          </tr>
          <tr  v-for="a in courses_dirty">
            <td><a :href="`/students/${a.id}`" target="_blank">{{a.email}}</a></td>
            <td>{{a.created_at | date}}</td>
            <td>{{a.courses_no_trial_count}}</td>
            <td>{{a.trial_count}}</td>
            <td>{{a.last_order_at | dateTime}}</td>
            <td>{{a.last_assignment_at| dateTime}}</td>
          </tr>
        </table>

        <h5>AC tagy</h5>
        <table class="table table-striped">
          <tr>
            <th>Student</th><th>created_at</th>
            <th>new
              _course</th>
            <th>new
              _gift</th>
            <th>new
              _trial</th>
            <th>Kurzů</th>
            <td>trialu</td>
            <th>Poslední objednávka</th>
            <th>Poslední přiřazení kurzu</th>
          </tr>
          <tr  v-for="a in ac_events">
            <td><a :href="`/students/${a.id}`" target="_blank">{{a.email}}</a></td>
            <td>{{a.created_at | date}}</td>
            <td>{{a.new_course}}</td>
            <td>{{a.new_gift}}</td>
            <td>{{a.new_trial}}</td>


            <td>{{a.courses_no_trial_count}} <span v-if="a.courses_helper">({{a.courses_helper}})</span></td>
            <td>{{a.trial_count}}</td>
            <td>{{a.last_order_at | dateTime}}</td>
            <td>{{a.last_assignment_at| dateTime}}</td>

          </tr>
        </table>

      </div>
    </modal>



</div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data(){
    return {
      info:{},
      courses_dirty: {},ac_events:{}
    }
  },
  filters: {

    date(value) {
      value = moment(value).format('DD. MM. YYYY')
      return value
    },

    dateTime(value) {
      value = moment(value).format('DD. MM. YYYY, H:mm')
      return value
    },
  },
  name: "system-statistics",
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
    this.loadData()
  },

  methods:{
    loadData(){
      const that = this
      axios.get(`/logs/system-statistics?t=${Math.floor(Date.now() / 1000)}`).then(response => {
        this.info = response.data.info
        setTimeout(()=>{
          that.loadData()
        }, 12000)
      })
    },

     show_students_modal() {
      this.courses_dirty=[]
       this.$modal.show('synchro-log-modal')

       axios.get(`/logs/synchro_status?t=${Math.floor(Date.now() / 1000)}`).then(response => {
         this.courses_dirty = response.data.info.courses_dirty
        // this.ac_events = response.data.info.ac_events
       })



     }


  }
}
</script>

<style scoped>
.modbod{
  text-align: left;
  height:450px;
  overflow-y: scroll;
  margin:20px;

}
</style>